import React, { useEffect, useState } from 'react';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Paper, Box, Typography } from '@mui/material';

// Helper function to build hierarchy for nested data
const buildHierarchy = (data, nodeKey, childrenKey) => {
  const nodeMap = new Map();
  data.forEach(node => nodeMap.set(node[nodeKey], { ...node, [childrenKey]: [] }));

  const hierarchy = [];
  data.forEach(node => {
    if (node.parent) {
      const parentNode = nodeMap.get(node.parent);
      if (parentNode) {
        parentNode[childrenKey].push(nodeMap.get(node[nodeKey]));
      } else {
        console.warn("Parent not found for node:", node);
        hierarchy.push(nodeMap.get(node[nodeKey])); // Add as top-level if orphaned
      }
    } else {
      hierarchy.push(nodeMap.get(node[nodeKey]));
    }
  });
  return hierarchy;
};

const TreeView = ({ nestedData, dataKey, labelKey, nodeKey, childrenKey }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Extract the data directly from nestedData
    const dataArray = nestedData?.data;

    if (!Array.isArray(dataArray)) {
      console.error("nestedData should contain a 'data' array:", nestedData);
      return;
    }

    // Determine if the data is flat or hierarchical
    const isHierarchical = dataArray.some(item => item.parent);

    if (isHierarchical) {
      // Build hierarchy if there are parent-child relationships
      const hierarchy = buildHierarchy(dataArray, nodeKey, childrenKey);
      setData(hierarchy);
    } else {
      // Otherwise, treat as flat data
      setData(dataArray);
    }
  }, [nestedData, nodeKey, childrenKey]);

  // Recursive function to render the tree, supports flat and hierarchical nodes
  const renderTree = (nodes) =>
    nodes.map((node) => (
      <TreeItem
        key={node[nodeKey]}
        itemId={node[nodeKey]}
        label={
          <Box display="flex" alignItems="center" style={{ userSelect: 'none', padding: '5px', minWidth: '200px' }}>
            <Box display="flex" flexDirection="column" style={{ flexGrow: 1 }}>
              <Typography variant="body2" style={{ fontSize: '16px', fontWeight: 'bold', color: '#333' }}>
                {node.code} {/* Display the code */}
              </Typography>
              <Typography variant="body2" style={{ fontSize: '15px', color: '#777', fontStyle: 'italic' }}>
                {node[labelKey] || 'No description available'} {/* Display the description */}
              </Typography>
            </Box>
          </Box>
        }
      >
        {node[childrenKey]?.length > 0 && renderTree(node[childrenKey])}
      </TreeItem>
    ));

  return (
    <Box
    style={{
      padding: '16px',
      //width: '50vw', // Make the width 50% of the viewport width
      minWidth: '50%', // Ensure it doesn't shrink below 50% of the screen width
      maxHeight: '70vh', // Limit height to the viewport height
      height: 'auto', // Let the height be dynamic based on content
      overflowY: 'auto', // Enable vertical scrolling if the content overflows
    }}
  >
    <SimpleTreeView>
      {renderTree(data)}
    </SimpleTreeView>
  </Box>
  );
};

export default TreeView;
