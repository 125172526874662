import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import { Nav, Navbar } from 'react-bootstrap';
import { Stack, Box, Divider } from '@mui/material';
import HeaderWithClipboard from './HeaderWithClipboard';
import HeaderBarOrgDropdown from './HeaderBarOrgDropdown';
import HeaderBarUserDropdown from './HeaderBarUserDropdown';
import IconButton from '@mui/material/IconButton';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Modal, Button } from 'react-bootstrap';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DeleteIcon from '@mui/icons-material/Delete';
import Toast from 'react-bootstrap/Toast';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useUserManagement } from '../../contexts/UserManagementContexts';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import CustomAlert from './CustomAlert';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function HeaderBar({ homeLink, homeText, userInfo, onOrgSwitch, switchingAllowed = false, hasEditPermission, wide }) {
    const { currentOrg: currentOrgParam, sessionId, workspace } = useParams();
    const { user, organizations, currentOrg, setCurrentOrg, isLoadingOrgs, error } = useUserManagement();

    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;

    const showDeleteButton = pathname.includes('results');

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showProblemModal, setShowProblemModal] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    // Alert message
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('info'); // You can change the default type as needed

    let config = {
        headers: {
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY
        }
    };

    useEffect(() => {
        // Ensure initial organization is set if on a page (Welcome) where no org in url params
        if (organizations.length > 0 && !isLoadingOrgs && !currentOrg) {
            if (currentOrgParam) {
                setCurrentOrg(organizations.find(organizations => organizations.name === currentOrgParam));
                if (onOrgSwitch) {
                    onOrgSwitch(organizations.find(organizations => organizations.name === currentOrgParam));
                }
            }
            else {
                console.log('Triggered')
                setCurrentOrg(organizations[0]);
                console.log(currentOrg)
                if (onOrgSwitch) {
                    onOrgSwitch(organizations[0])
                }
            }
        }

    }, [isLoadingOrgs, organizations, onOrgSwitch]);

    const handleOrgSwitch = (orgId) => {
        if (!switchingAllowed) {
            console.log("Organization switching is not allowed.");
            return;
        }

        const selectedOrg = organizations.find((org) => org.id === orgId);
        if (selectedOrg) {
            setCurrentOrg(selectedOrg);
            onOrgSwitch(selectedOrg);
        }
    };

    const deleteSession = async () => {
        setShowSpinner(true);

        try {
            const response = await axios.get(`${API_BASE_URL}/api/DeleteSession?containerName=${currentOrg.name}&virtualFolder=${workspace}/${sessionId}`, config);
            triggerAlert("Delete successful.")
        }
        catch {
            triggerAlert('Delete failed.', 'error')
        }
        finally {
            setShowSpinner(false);
            // Wait for 2 seconds before navigating
            await new Promise(resolve => setTimeout(resolve, 2000));
            navigate(`/${currentOrg}/${workspace}/workspace`)

        }
    }

    const triggerAlert = (newMessage, newType) => {
        setAlertMessage(newMessage);
        setAlertType(newType);
        setShowAlert(true); // This will trigger the alert to show
    };

    return (
        <>
            <Box expand='sm' className='global-nav'>
                <Container style={{ padding: wide ? '0 2rem' : 'auto', maxWidth: wide ? '100%' : null }}>
                    <Stack direction='row' sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <a className='navbar-brand' href={homeLink}>{homeText}</a>
                        <Stack direction='row' spacing={1} style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
                            {sessionId && <HeaderWithClipboard fieldValue={sessionId} />}
                            {showDeleteButton && hasEditPermission &&
                                <>
                                    <IconButton title='Delete session' aria-label="delete" style={{ border: 'solid 1px white' }} onClick={() => setShowDeleteModal(true)}>
                                        <DeleteIcon style={{ color: 'white' }} fontSize='small' />
                                    </IconButton>
                                    <Divider orientation='vertical' flexItem style={{ border: 'solid 1px white' }} />
                                </>
                            }

                            {organizations && organizations.length > 0 && (
                                <HeaderBarOrgDropdown
                                    currentOrg={currentOrg}
                                    organizations={organizations}
                                    handleOrgSwitch={handleOrgSwitch}
                                    switchingAllowed={switchingAllowed}
                                />
                            )}
                            <HeaderBarUserDropdown user={userInfo} />
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            {/* <div>
                <div className='secondary-background'>
                    <Navbar expand="sm" className="header-bar">
                        <Container>
                            <Navbar.Brand href={homeLink}>{homeText}</Navbar.Brand>
                            <div>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
                                    <Navbar.Text className='d-flex align-items-center'>
                                        {sessionId && (
                                            <div className='d-flex ms-2'>
                                                <div className='me-2'>Session key:</div>
                                                <div className='pe-2'>
                                                    <HeaderWithClipboard style={{ color: 'inherit' }} fieldValue={sessionId} />
                                                </div>
                                            </div>
                                        )}
                                        <div style={{ borderRight: '1px solid white', borderLeft: '1px solid white', marginRight: '1rem' }}>
                                            {showDeleteButton && <IconButton title='Delete session' aria-label="delete" style={{ border: 'solid 1px white', marginLeft: '1rem' }} onClick={() => setShowDeleteModal(true)}>
                                                <DeleteIcon style={{ color: 'white' }} fontSize='small' />
                                            </IconButton>}
                                            <IconButton title='Get help' aria-label="help" style={{ border: 'solid 1px white', marginLeft: '1rem', marginRight: '1rem' }} onClick={() => setShowHelpModal(true)}>
                                                <QuestionMarkIcon style={{ color: 'white' }} fontSize='small' />
                                            </IconButton>
                                            <IconButton title='Provide feedback' aria-label="incident" style={{ border: 'solid 1px white', marginRight: '1rem' }} onClick={() => setShowProblemModal(true)}>
                                                <ReportProblemIcon style={{ color: 'white' }} fontSize='small' />
                                            </IconButton>
                                        </div>
                                        {organizations && organizations.length > 0 && (
                                            <HeaderBarOrgDropdown
                                                currentOrg={currentOrg}
                                                organizations={organizations}
                                                handleOrgSwitch={handleOrgSwitch}
                                                switchingAllowed={switchingAllowed}
                                            />
                                        )}
                                        <HeaderBarUserDropdown user={userInfo} />
                                    </Navbar.Text>
                                </Navbar.Collapse>
                            </div>
                        </Container>
                    </Navbar>
                </div> */}

            {/* Modals for Help, Problem, Delete Confirmation */}
            {/* ... (Modal code remains the same) */}

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showSpinner}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Toast onClose={() => setShowToast(false)} delay={3000} show={showToast} autohide className="position-fixed top-0 start-50 translate-middle-x">
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showSpinner}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ConfirmDeleteModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                fieldName={sessionId}
                onDelete={deleteSession}
            />

            <CustomAlert message={alertMessage} type={alertType} showAlert={showAlert} setShowAlert={setShowAlert} />
        </>
    );
}

export default HeaderBar;
