import React, { useState, useEffect } from "react";
import { Box, Divider, CircularProgress, Tooltip, IconButton, Drawer, Button } from '@mui/material';
import { Settings as SettingsIcon } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { Row, Col, Nav, Tab } from 'react-bootstrap';
import ReactCodeMirror from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';
import ModifyWorkspaceStandard from "./ModifyWorkspaceStandard";
import DefineWorkspaceEnrichers from "./DefineWorkspaceEnrichers";

const WorkspaceRefinery = ({ workspaceStandard, setWorkspaceStandard, handleSaveWorkspaceStandard, isSaveButtonEnabled, hasCreatePermission }) => {
    const [selectedTab, setSelectedTab] = useState(1);
    const [isAdvancedModeOpen, setIsAdvancedModeOpen] = useState(false);
    const [jsonValue, setJsonValue] = useState(JSON.stringify(workspaceStandard, null, 2)); // Store the JSON value locally
    const [isJsonChanged, setIsJsonChanged] = useState(false); // Track if changes are made in JSON editor

    // Toggle between Classifiers and Enrichers tabs
    const handleTabSelect = (tab) => {
        setSelectedTab(tab);
    };

    // Toggle the advanced editor (JSON editor)
    const toggleAdvancedMode = () => {
        setIsAdvancedModeOpen(!isAdvancedModeOpen);
    };

    // Handle changes in the JSON editor
    const handleJsonChange = (value) => {
        setJsonValue(value); // Update the local state with the current value
        setIsJsonChanged(true); // Enable save button when changes are made
        try {
            const parsedJson = JSON.parse(value);
            setWorkspaceStandard(parsedJson);
        } catch (e) {
            console.error("Invalid JSON"); // Handle invalid JSON gracefully
        }
    };

    // Save JSON from the editor
    const handleJsonSave = () => {
        setIsJsonChanged(false); // Reset the change flag
        setJsonValue(JSON.stringify(workspaceStandard, null, 2)); // Set the saved value back to the editor
        handleSaveWorkspaceStandard(); // Call the save function
    };

    // Cancel changes made in the JSON editor
    const handleJsonCancel = () => {
        setJsonValue(JSON.stringify(workspaceStandard, null, 2)); // Revert to the original value
        setIsJsonChanged(false); // Reset the change flag
    };

    return (
        <Box sx={{ margin: '1.5rem', flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {/* Title and Buttons Area */}
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {/* Placeholder for Title if needed */}
            </div>

            {Object.keys(workspaceStandard).length > 0 ? (
                <div style={{ width: '100%' }}>
                    <Tab.Container activeKey={selectedTab} onSelect={handleTabSelect}>
                        <Row className="align-items-center">
                            <Col>
                                <Nav className="nav nav-pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey={1}>Classifiers</Nav.Link>
                                    </Nav.Item>
                                    <Divider style={{ margin: '0 2px' }} />
                                    <Nav.Item>
                                        <Nav.Link eventKey={2}>Enrichers</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col xs="auto" className="text-end">
                            <Tooltip 
                                title={
                                    hasCreatePermission
                                    ? 'Advanced Workspace Standard Editor'
                                    : 'Advanced Workspace Standard Editor - 🚫 Off-limits to Guest users'
                                }
                                >
                                <span> {/* Wrap the IconButton in a <span> to prevent it from being clickable when disabled */}
                                    <IconButton
                                    onClick={toggleAdvancedMode}
                                    size="small"
                                    sx={{ marginRight: 2 }}
                                    disabled={!hasCreatePermission} // Disable the button if permission is false
                                    >
                                    <SettingsIcon />
                                    </IconButton>
                                </span>
                                </Tooltip>

                                {/* Save Button */}
                                <Tooltip title="Save Workspace Standard">
                                    <span>
                                        <IconButton
                                            onClick={handleSaveWorkspaceStandard}
                                            size="small"
                                            sx={{
                                                cursor: isSaveButtonEnabled ? 'pointer' : 'not-allowed',
                                                color: isSaveButtonEnabled ? '#0051B6' : '#ccc',
                                                fontSize: '50px',
                                            }}
                                        >
                                            <SaveIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Col>
                        </Row>

                        <Divider style={{ border: '1px solid grey', margin: '8px 0' }} />
                        <Tab.Content>
                            <Tab.Pane eventKey={1}>
                                <ModifyWorkspaceStandard workspaceStandard={workspaceStandard} setWorkspaceStandard={setWorkspaceStandard} />
                            </Tab.Pane>
                            <Tab.Pane eventKey={2}>
                                <DefineWorkspaceEnrichers workspaceStandard={workspaceStandard} setWorkspaceStandard={setWorkspaceStandard} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            ) : (
                <CircularProgress />
            )}

            {/* Advanced Mode Panel (JSON Editor) */}
            <Drawer
                anchor="right"
                open={isAdvancedModeOpen}
                onClose={toggleAdvancedMode}
                sx={{ '& .MuiDrawer-paper': { width: '50%' } }}
            >
                <Box sx={{ padding: '1rem', height: '100%', overflowY: 'auto' }}>
                    {/* JSON Editor Buttons */}
                    <Box sx={{ display: 'flex', justifyContent: 'left', marginBottom: '1rem' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleJsonSave}
                            disabled={!isJsonChanged}
                            style={{ marginRight:"10px" }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleJsonCancel}
                            disabled={!isJsonChanged} // Disable Cancel if no changes are made
                        >
                            Cancel
                        </Button>
                    </Box>

                    {/* CodeMirror JSON Editor */}
                    <ReactCodeMirror
                        value={jsonValue}
                        extensions={[json()]}
                        height="100%"
                        theme="dark"
                        onChange={(value) => handleJsonChange(value)}
                        basicSetup={{
                            lineNumbers: true,
                            foldGutter: true,
                            highlightActiveLine: true,
                        }}
                    />
                </Box>
            </Drawer>
        </Box>
    );
};

export default WorkspaceRefinery;
