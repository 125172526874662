import React, { useState } from "react";
import { Typography, Box } from '@mui/material';
import { Stack } from "@mui/material";
import { Form, Nav, Tab, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const ModifyWorkspaceStandard = ({ workspaceStandard, setWorkspaceStandard }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedRows, setSelectedRows] = useState(new Set());
    const isSelected = selectedRows.size > 0;
    const [showPrompt, setShowPrompt] = useState(false);

    const addRow = (codePartIndex) => {
        setWorkspaceStandard((prevWorkspaceStandard) => ({
            ...prevWorkspaceStandard,
            classifiers: prevWorkspaceStandard.classifiers.map((codePart, index) =>
                index === codePartIndex
                    ? {
                        ...codePart,
                        data: [...codePart.data, { code: '', description: '' }], // Add the new row at the end of the data array
                    }
                    : codePart
            ),
        }));
    };

    const updateRow = (codePartIndex, rowIndex, field, value) => {
        setWorkspaceStandard((prevWorkspaceStandard) => ({
            ...prevWorkspaceStandard,
            classifiers: prevWorkspaceStandard.classifiers.map((codePart, index) =>
                index === codePartIndex
                    ? {
                        ...codePart,
                        data: codePart.data.map((rowData, rowIdx) =>
                            rowIdx === rowIndex ? { ...rowData, [field]: value } : rowData
                        ),
                    }
                    : codePart
            ),
        }));
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            // Select all
            const allSelected = new Set(filteredData.map((_, index) => index)); // Create a set of all row indexes
            setSelectedRows(allSelected); // Set selected rows state to all selected
        } else {
            // Deselect all
            setSelectedRows(new Set()); // Clear selected rows
        }
    };

    const filteredData = workspaceStandard?.classifiers[selectedTab]?.data.filter(row =>
        row.code.includes(searchTerm) || row.description.includes(searchTerm)
    );

    const handleRowSelect = (rowIndex) => {
        const newSelectedRows = new Set(selectedRows);
        if (newSelectedRows.has(rowIndex)) {
            newSelectedRows.delete(rowIndex);
        } else {
            newSelectedRows.add(rowIndex);
        }
        setSelectedRows(newSelectedRows); // Trigger re-render
    };

    const handleBulkDelete = () => {
        setWorkspaceStandard((prevWorkspaceStandard) => ({
            ...prevWorkspaceStandard,
            classifiers: prevWorkspaceStandard.classifiers.map((codePart, index) =>
                index === selectedTab
                    ? {
                        ...codePart,
                        data: codePart.data.filter((_, rowIndex) => !selectedRows.has(rowIndex)),
                    }
                    : codePart
            ),
        }));
        setSelectedRows(new Set()); // Reset selection
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            addRow(selectedTab); // Trigger adding a new row
        }
    };

    return (
        <>
            {workspaceStandard &&
                <Tab.Container activeKey={`codePart-${selectedTab}`}>
                    <Stack direction='row' spacing={1} style={{ width: '100%' }}>
                        <Nav style={{ borderRadius: '5px', width: '20%', minWidth: '20%' }} variant="pills" className="flex-column">
                            {workspaceStandard.classifiers.map((codePart, index) => (
                                <Nav.Item key={index} style={{ marginTop: "5px" }}>
                                    <Nav.Link
                                        eventKey={`codePart-${index}`}
                                        className={selectedTab === index ? 'active custom-nav-link' : 'custom-nav-link'}
                                        onClick={() => {
                                            setSelectedTab(index)
                                            setSelectedRows(new Set())
                                        }}
                                    >
                                        {codePart.name}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content style={{ flexGrow: 1 }}>
                            {workspaceStandard.classifiers.map((codePart, index) => (
                                <Tab.Pane className="tab-content" key={index} eventKey={`codePart-${index}`}>
                                    <div className="d-flex w-100 align-items-center">
                                        <Form.Control
                                            type="text"
                                            placeholder="Search..."
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="me-2"
                                            style={{ width: 'auto', minWidth: '400px', maxWidth: '500px' }}
                                        />
                                    </div>
                                    <Table responsive style={{ tableLayout: 'fixed' }}>
                                        <thead>
                                            <tr style={{ height: '60px' }}>
                                                <th style={{ width: '50px', verticalAlign: 'middle', textAlign: 'center', backgroundColor: "transparent" }}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={selectedRows.size === filteredData.length} // Check if all are selected
                                                        indeterminate={selectedRows.size > 0 && selectedRows.size < filteredData.length ? true : undefined}
                                                        onChange={handleSelectAll} // Handle the change to select or deselect all
                                                        title="Select All"
                                                    />
                                                </th>
                                                <th style={{ width: '140px', verticalAlign: 'middle', backgroundColor: "transparent" }}>
                                                    <div className="d-flex">
                                                        <div
                                                            style={{
                                                                maxWidth: '70px',
                                                                cursor: isSelected ? 'pointer' : '',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                marginRight: '20px'
                                                            }}
                                                            onClick={handleBulkDelete}
                                                            title="Delete Selected"
                                                            className="delete-icon"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faTrashAlt}
                                                                style={{
                                                                    fontSize: '16px',
                                                                    color: isSelected ? 'red' : 'gray', // Change color based on selection
                                                                    transition: 'color 0.2s ease', // Smooth transition
                                                                }}
                                                            />
                                                        </div>
                                                        Code
                                                        <AddCircleIcon
                                                            onClick={() => addRow(index)}
                                                            style={{ marginLeft: "15px", color: 'green', cursor: 'pointer' }} />
                                                    </div>
                                                </th>
                                                <th style={{ verticalAlign: 'middle', backgroundColor: "transparent" }}>
                                                    <div className="d-flex">
                                                        Description
                                                        <Form.Check
                                                            type="switch"
                                                            id="toggle-prompt"
                                                            label=""
                                                            checked={showPrompt}
                                                            onChange={() => setShowPrompt(!showPrompt)}
                                                            style={{ marginLeft: '10px' }}
                                                        />
                                                    </div>
                                                </th>
                                                {showPrompt && <th style={{ verticalAlign: 'middle', backgroundColor: "transparent" }}>Prompt</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData?.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    <td style={{ maxWidth: '50px', textAlign: 'center', verticalAlign: 'top', backgroundColor: "transparent", borderBottom: "none" }}>
                                                        <Form.Check
                                                            type="checkbox"
                                                            onChange={() => handleRowSelect(rowIndex)}
                                                            checked={selectedRows.has(rowIndex)}
                                                            style={{ margin: 0 }} // Reduce margin
                                                        />
                                                    </td>
                                                    <td style={{ verticalAlign: 'top', backgroundColor: "transparent", borderBottom: "none" }}>
                                                        <Form.Control
                                                            type="text"
                                                            value={row.code}
                                                            onChange={(e) => updateRow(index, rowIndex, 'code', e.target.value)}
                                                            style={{ width: '130px' }} // Reduce width of the code input
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                    </td>
                                                    <td style={{ maxWidth: '50px', verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                        <Form.Control
                                                            as={showPrompt ? "textarea" : "input"} // Toggle between textarea and input
                                                            rows={showPrompt ? 3 : 1} // Change rows based on the toggle
                                                            value={row.description}
                                                            onChange={(e) => updateRow(index, rowIndex, 'description', e.target.value)}
                                                            style={{ resize: 'none', overflow: 'hidden', maxWidth: '750px' }} // Disable resizing
                                                            onKeyDown={handleKeyDown}
                                                        />
                                                    </td>
                                                    {showPrompt && (
                                                        <td style={{ verticalAlign: 'middle', backgroundColor: "transparent", borderBottom: "none" }}>
                                                            <Form.Control
                                                                as="textarea" // Always a textarea for prompt
                                                                rows={3} // Set number of visible rows for prompt
                                                                value={row.prompt}
                                                                onChange={(e) => updateRow(index, rowIndex, 'prompt', e.target.value)}
                                                                style={{ resize: 'none', overflow: 'hidden' }} // Disable resizing
                                                                onKeyDown={handleKeyDown}
                                                            />
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Stack>
                </Tab.Container>
            }
        </>
    );
}

export default ModifyWorkspaceStandard;
