import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Container, Row, Col, Badge, Card, Form, Alert, Modal, Button } from 'react-bootstrap';
import Chip from '@mui/material/Chip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TypingEffect from './subcomponents/TypingEffect';
import Pricing from './subcomponents/Pricing';
import RoiCalculator from './subcomponents/RoiCalculator';
import ThreePartProcess from './subcomponents/ThreePartProcess';
import Subscribe from './subcomponents/Subscribe';
import RequestADemo from './subcomponents/RequestADemo';
import NavBar from './shared/NavBar'
import FooterBar from './shared/FooterBar';
import Popup from './shared/Popup';
import Overlay from './shared/Overlay';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import LoginButton from './shared/LoginButton';
import LogoutButton from './shared/LogoutButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from "react-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight, faPuzzlePiece, faFileImport, faWandSparkles, faArrowUpRightFromSquare

} from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import { useSession } from '../contexts/SessionContexts';
import '../App.scss';
import ReactGA from 'react-ga';
import { useAuth0 } from "@auth0/auth0-react";
import ArticleCard from './shared/ArticleCard';
import { Avatar, Box, CardActionArea, Grid, Stack, Typography } from '@mui/material';
import MainHeader from './subcomponents/home/MainHeader';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const Home = (props) => {
  const navigate = useNavigate(); // for handling page routing within a function. 
  const location = useLocation();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { setSession } = useSession();
  const { sessionGuid } = useSession();
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showStartupModal, setShowStartupModal] = useState(false);
  const [resultGuid, setResultGuid] = useState('');
  const inputRef = useRef(null);
  const [showSpinner, setShowSpinner] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [userId, setuserId] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [APIError, setAPIError] = useState(null);
  const [formError, setFormError] = useState(null);
  const [showSubscribe, setShowSubscribe] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  // Subscription modal popup on page scroll
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [subscribeModalShown, setSubscribeModalShown] = useState(false);

  const benefits = [
    {
      statement: "Get to the facts",
      assertion: "Extract insights buried deep in your documents",
      icon: "📊"
    },
    {
      statement: "Flag risks",
      assertion: "Audit your data to signpost gaps or inconsistencies",
      icon: "⚠️"
    },
    {
      statement: "Tackle new regulation",
      assertion: "Evidence compliance with IM standards",
      icon: "📜"
    },
    {
      statement: "Respond to change",
      assertion: "Re-classify legacy data to meet changing requirements",
      icon: "🔄"
    },
    {
      statement: "Get organised",
      assertion: "Connect your document repositories to sort data at source",
      icon: "🗂️"
    }
  ]

  const lastHash = useRef('');

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);


  // Google analytics ping on component mount
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // For conditionally styling components based on window width. 
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Auto-ping login button when user returning from email validation on sign-up
  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    return {
      message: queryParams.get('message'),
      success: queryParams.get('success'),
      // Add more parameters as needed
    };
  };

  const { message, success } = getQueryParams();

  useEffect(() => {
    if (success === 'true' && !isAuthenticated) {
      // Auto-trigger login with redirect if success parameter is true and user is not authenticated
      loginWithRedirect();
    }
  }, [success, isAuthenticated, loginWithRedirect]);

  const handleShowSessionModal = () => setShowSessionModal(true);
  const handleHideSessionModal = () => {
    setShowSessionModal(false);
    setResultGuid('');
    handleFormReset('EnterSessionKey')
  }

  const handleShowStartupModal = () => setShowStartupModal(true);
  const handleHideStartupModal = () => {
    setShowStartupModal(false);
    handleFormReset('EnterSessionKey')
  }


  const handleGetResultsButtonClick = (event) => {

    const fetchUserStandard = async () => {
      try {

        const config = {
          headers: {
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
          }
        }

        const response = await axios.get(`${API_BASE_URL}/api/ConfigureResultsTableTrigger?sessionGuid=${resultGuid}&folderName=${userId}`, config);
        setShowSpinner(false);
        setSession(resultGuid, userId);
        navigate(`${userId}/${resultGuid}/results`)
        // if (props.onGetResultsButtonClick) {
        //   props.onGetResultsButtonClick();
        // }
      } catch (error) {
        setShowSpinner(false)
        console.error("Error fetching userStandard:", error);
        setAPIError('Session key not found');
      }
    };

    event.preventDefault();
    event.stopPropagation();

    // Trigger form validation. 
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setFormError('Cannot be blank')
      setValidated(true);
      return;
    }

    setShowSpinner(true);

    fetchUserStandard();

  };

  const handleAuthenticate = (event) => {

    event.preventDefault();
    event.stopPropagation();

    // Trigger form validation. 
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setFormError('Cannot be blank')
      setValidated(true);
      return;
    }

    const passwordSetting = process.env[`REACT_APP_USER_${userId}`];

    if (passwordSetting && password === passwordSetting) {

      setAPIError(null)
      navigate(`${userId}/${sessionGuid}/initiate`)

    }
    else {
      setAPIError('Invalid userId or password')
    }
  }

  const handleAlertClose = () => {
    setAPIError(null);
  };

  const handleRegisterInterest = () => {
    if (showSubscribe) {
      setShowSubscribe(false)
    } else {
      setShowSubscribe(true); // Show the Subscribe component
    }

  };

  const handleFormReset = (formId) => {
    const form = document.getElementById(formId);
    if (form) {
      form.reset();
    } else {
      console.error(`Form with ID '${formId}' not found.`);
    }
    setAPIError(null);
    setFormError(null);
    setValidated(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (showSessionModal) {
      inputRef.current.focus();
    }
  }, [showSessionModal]);


  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleImageClick = (event, src) => {
    event.preventDefault(); // Prevent the default behavior (scrolling to the top)
    setImageSrc(src);
    setShowModal(true);
    event.stopPropagation(); // Prevent the event from bubbling up to the <a> tag
  };

  useEffect(() => {
    // Check if subscribeModalShown is stored in local storage
    const storedSubscribeModalShown = localStorage.getItem('subscribeModalShown');

    // Initialize subscribeModalShown state based on local storage value
    if (storedSubscribeModalShown) {
      setSubscribeModalShown(true);
    }
  }, []);


  // Subscribe modal effects and event listener
  useEffect(() => {
    const handleScroll = () => {
      const featuresSection = document.getElementById('features');
      if (featuresSection) {
        const rect = featuresSection.getBoundingClientRect();
        // If the modal has not been shown yet
        if (!subscribeModalShown) {
          // Open the modal when scrolling to the features section
          if (rect.top <= window.innerHeight) {
            setShowSubscribeModal(true);
            setSubscribeModalShown(true);
            // Store subscribeModalShown in local storage
            localStorage.setItem('subscribeModalShown', 'true');
          }
        }
      }
    };

    // Debounce scroll events to avoid rapid state updates
    let timeoutId;
    const debouncedHandleScroll = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleScroll, 100);
    };

    window.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [subscribeModalShown]);


  const handleCloseSubscribeModal = () => {
    setShowSubscribeModal(false);
  };

  const handleManualSubscribeModalRecall = () => {
    setShowSubscribeModal(true);
    setSubscribeModalShown(true);
    localStorage.setItem('subscribeModalShown', 'true');
  };

  return (
    <div className='page-wrapper'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavBar variant='white' banner={true}/>
      <main className="main-wrapper">
        <div className="home-hero_section">
          <div className="background-placeholder">
            <video className="background-video" autoPlay loop width='100%' height='100%' muted playsInline>
              <source src={`${process.env.PUBLIC_URL}/home_background.mp4`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <Container className="home-strapline">
            <Row style={{ paddingTop: '13vh' }}>
              <Col xxl={6} lg={6} md={8} sm={12}>
                <div className="hidden-on-mobile">
                  <TypingEffect />
                </div>
                <h1 className="highlight-text">
                  Information management for the AI era.
                </h1>
                <Col xxl={8} md={12}>
                  <div className='mt-4'>
                    <p className='m-0 fs-5'>Introducing hoppa - the smarter way to turn built environment data into organised and actionable knowledge.</p><br></br>
                    {/*<p>Transform unstructured data into insights, enhance productivity, and unlock the hidden value of your industry data.</p>*/}
                  </div>
                  <div className="mt-2 d-flex">
                    <button className="btn btn-secondary ms-0">
                      <ScrollLink spy to="features">
                        Learn more
                      </ScrollLink>
                    </button>
                    <button className="btn btn-primary">
                      <ScrollLink spy to="contact">
                        Arrange a demo
                      </ScrollLink></button>
                  </div>
                </Col>

              </Col>
            </Row>
          </Container>
        </div>
        <Container id='why-hoppa' style={{ paddingTop: '120px' }}>
          {/* <Chip label="Why hoppa" variant="outlined" className='ps-2 pe-2 mb-2 tag-selected' />*/}
          <Row>
            <Col xs={12} lg={6}>

              <p className='blue-font xlarge-font bold-statement'>Taking charge of data has never been easier</p>
              {/* <p className='mt-2'>Supply chain fragmentation, disparate technologies, and a long tail of historic, poorly managed data means 90% of built environment <b><i className="blue-font">data is unstructured and hard to work with.</i></b></p> */}
              <p className='mt-4'>Your cheat code to better information management. Our AI solutions refine raw and hard-to-work-with data into decision-making gold. Perfect when information changes hands, if things get out of hand, and as requirements change over time.</p>
              <p className='mb-4'> Say goodbye to labour-intensive document diving and data wrangling, and hello to a new era of productivity, transparency and control.</p>
              <Row className='mb-4'>

                <Col xs={2} md={1} className='ms-3 d-flex flex-column align-items-center'>
                  <div className='circle-static'>
                    <div className='circle-icon-static' >
                      <FontAwesomeIcon size='lg' icon={faPuzzlePiece} />
                    </div>
                  </div>
                  <div className='v1'>
                  </div>
                  <div className='circle-static'>
                    <div className='circle-icon-static' >
                      <FontAwesomeIcon size='lg' icon={faFileImport} />
                    </div>
                  </div>
                  <div className='v1'>
                  </div>
                  <div className='circle-static'>
                    <div className='circle-icon-static' >
                      <FontAwesomeIcon size='lg' icon={faWandSparkles} />
                    </div>
                  </div>
                </Col>
                <Col xs={9} md={7} className='d-flex flex-column justify-content-between'>
                  <p className='mb-3'>Specify how you need your data organised and the queries you need answered.</p>
                  <p className='mb-4'>Connect Hoppa to your data sources and sit back while our AI gets to work.</p>
                  <p className='mb-1'>Explore cleansed results, perform analysis, run automations, scan for compliance and much more.</p>
                </Col>
              </Row>
              <button className='btn btn-tertiary mt-5 mb-5 ms-0'>
                <ScrollLink spy to="benefits">
                  What could this mean for you?&nbsp;&nbsp;&nbsp;&nbsp;⮕
                </ScrollLink>

              </button>
              <Col>
              </Col>
            </Col>
            <Col xs={12} lg={6} className='d-flex justify-content-center'>
              <img
                style={{
                  width: window.innerWidth < 768 ? '100%' : '90%'
                }}
                src={`${process.env.PUBLIC_URL}/Hoppa-Landscape.svg`}


              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6} className='d-flex justify-content-center'>

            </Col>
            <Col xs={12} lg={6} className='d-flex align-items-center'>

            </Col>
          </Row>
        </Container>

        <div id='solutions' className='background-video-banner' style={{ paddingTop: '120px', paddingBottom: '0' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ position: 'relative' }}>
              <Box
                component="img"
                src={`${process.env.PUBLIC_URL}/red-cross.png`}
                sx={{
                  width: { xs: '85px', md: '100px' }, // Fixed width for the image
                  position: 'absolute',
                  bottom: '8px',
                  left: { xs: '50%', sm: '78%', md: '73%', lg: '73%', xl: '73%' }, // Responsive positioning
                  transform: 'translateX(-50%)', // Center the image on its `left` position
                }}
              />
              <MainHeader
                title='Turn your data into knowledge...'
              />
              <MainHeader
                title='...in minutes, not months.'
              />
            </div>
            <div style={{ paddingTop: '10px' }}>
              <Stack direction='row'>
                <button className="btn btn-secondary">
                  <a href="/How_hoppa_can_help_your_organisation.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    Explore use cases
                  </a>
                </button>
                <button
                  className="btn btn-primary ms-0"
                >
                  <ScrollLink spy to="contact">
                    Arrange a demo
                  </ScrollLink>
                </button>
              </Stack>
            </div>
          </div>
          <Container id='solutions' className='benefits-card_grid' style={{ paddingTop: '0px' }}>
            <div>
              {/*<Chip label="Solutions" variant="outlined" className='ps-2 pe-2 mb-3 tag-selected' />*/}
              <Row className='gx-2 gy-3'>
                <Col md={12} lg={4}>
                  <Card className='case-study'>
                    <Card.Img style={{ aspectRatio: '16 / 9' }} variant='top' src={`${process.env.PUBLIC_URL}/compass_design.png`} />
                    <Card.Title ><Chip variant='outlined' label='DATA DISCOVERY' size="small" className='mb-2' />
                      <h5 className='blue-font'>Uncover buried insights</h5>
                    </Card.Title>
                    {[
                      'Quickly sort through incoming data',
                      'Pinpoint information gaps',
                      'Take control of legacy and historic records'
                    ].map((text, index) => (
                      <Row key={index}>
                        <Col xs={1}><DoneIcon color='primary' /></Col>
                        <Col className='ms-1 mb-2'>{text}</Col>
                      </Row>
                    ))}
                  </Card>
                </Col>
                <Col md={12} lg={4}>
                  <Card className='case-study'>
                    <Card.Img style={{ aspectRatio: '16 / 9' }} variant='top' src={`${process.env.PUBLIC_URL}/construction_helmet.png`} />
                    <Card.Title><Chip variant='outlined' label='INFORMATION EXCHANGE' size="small" className='mb-2' />
                      <h5 className='blue-font'>Optimise data sharing</h5>
                    </Card.Title>
                    {[
                      'Point and shoot compliance with any IM standard',
                      'Prepare for handover without the pain',
                      'Supercharge inter-org knowledge transfer'
                    ].map((text, index) => (
                      <Row key={index}>
                        <Col xs={1}><DoneIcon color='primary' /></Col>
                        <Col className='ms-1 mb-2'>{text}</Col>
                      </Row>
                    ))}
                  </Card>
                </Col>
                <Col md={12} lg={4}>
                  <Card className='case-study'>
                    <Card.Img style={{ aspectRatio: '16 / 9' }} variant='top' src={`${process.env.PUBLIC_URL}/building_defence.png`} />
                    <Card.Title><Chip variant='outlined' label='GOLDEN THREAD' size="small" className='mb-2' />
                      <h5 className='blue-font'>Keep the facts to hand</h5>
                    </Card.Title>
                    {[
                      'Kick-start your information golden thread',
                      'Boost resilience and emergency readiness',
                      'Find facts in digital records instead of physical surveys',
                    ].map((text, index) => (
                      <Row key={index}>
                        <Col xs={1}><DoneIcon color='primary' /></Col>
                        <Col className='ms-1 mb-2'>
                          {text} {index === 0 && <a href="https://buildingsafety.campaign.gov.uk/making-buildings-safer/building-safety-regulator-news/understanding-the-golden-thread/" target="_blank" rel="noopener noreferrer"><OpenInNewIcon style={{ fontSize: 16, verticalAlign: 'middle', marginLeft: "3px", marginBottom: "3px" }} /></a>}
                        </Col>
                      </Row>
                    ))}

                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container id="features" className='home-detail_section' style={{ paddingTop: '120px' }}>
          <div>
            {/*<Chip label="How it works" variant="outlined" className='ps-2 pe-2 mb-4 tag-selected' />*/}
            <Container>
              <MainHeader title='More effective than Copilot, less expensive than custom solutions' subtitle='Our tailored services harness the power of AI to propel your teams to new heights.' />
            </Container>
          </div>
          <div className='padding-huge'>
            <Row className='product-text-img_grid mt-5'>
              <Col xs={12} lg={6}>
                <p className='medium-font blue-font'>Get to the detail faster</p>
                <p className='mt-2'>Unlike other solutions, our AI is ready to go out-the-box. All you have to do is pick from a list of popular classification standards, or define your own.</p>
              </Col>
              <Col xs={{ order: windowWidth < 991 ? 'first' : 'last' }}>
                <div className="gif-container">
                  <a className="gif-link" href="#" onClick={handleImageClick}>
                    <img
                      className="product-slider-image-3d"
                      src={`${process.env.PUBLIC_URL}/hoppa_img_config.png`}
                      alt="GIF"
                      onClick={(event) => handleImageClick(event, `${process.env.PUBLIC_URL}/hoppa_img_config.png`)}
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <div className='padding-huge'>
            <Row className='product-text-img_grid'>
              <Col xs={12} lg={6}>
                <p className='medium-font blue-font'>Find those needles in the haystack</p>
                <p className='mt-2'>Tell Hoppa the key questions you want to ask of your data and our powerful Natural Language algorithms will find the answers nested deep in your files.</p>
              </Col>
              <Col xs={{ order: windowWidth < 991 ? 'first' : 'last' }}>
                <div className="gif-container">
                  <a className="gif-link" href="#" onClick={handleImageClick}>
                    <img
                      className="product-slider-image-3d"
                      src={`${process.env.PUBLIC_URL}/hoppa_gif_search.gif`}
                      alt="GIF"
                      onClick={(event) => handleImageClick(event, `${process.env.PUBLIC_URL}/hoppa_gif_search.gif`)}
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <div className='padding-huge'>
            <Row className='product-text-img_grid'>
              <Col xs={12} lg={6}>
                <p className='medium-font blue-font'>Trustworthy AI that puts you in the driving seat</p>
                <p className='mt-2'>Harness the power of the latest large language models to process textual and image based files. Our AI is traceable, transparent and modest - it explains its thinking and flags when it needs human assistance.</p>
              </Col>
              <Col xs={{ order: windowWidth < 991 ? 'first' : 'last' }}>
                <div className="gif-container">
                  <a className="gif-link" href="#" onClick={handleImageClick}>
                    <img
                      className="product-slider-image-3d"
                      src={`${process.env.PUBLIC_URL}/hoppa_gif_results.gif`}
                      alt="GIF"
                      onClick={(event) => handleImageClick(event, `${process.env.PUBLIC_URL}/hoppa_gif_results.gif`)}
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          <div className='padding-huge'>
            <Row className='product-text-img_grid'>
              <Col xs={12} lg={6}>
                <p className='medium-font blue-font'>Unlock the power of structured information</p>
                <p className='mt-2'>Upload your files and receive results in minutes - Hoppa will sort your files into a digestible, editable and exportable information pack.</p>
              </Col>
              <Col xs={{ order: windowWidth < 991 ? 'first' : 'last' }}>

                <div className="gif-container">
                  <a className="gif-link" href="#" onClick={handleImageClick}>
                    <img
                      className="product-slider-image-3d"
                      src={`${process.env.PUBLIC_URL}/hoppa_gif_exports.gif`}
                      alt="GIF"
                      onClick={(event) => handleImageClick(event, `${process.env.PUBLIC_URL}/hoppa_gif_exports.gif`)}
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </div>
          {/* Modal for rendering all gifs */}
          <Modal show={showModal} onHide={toggleModal} centered className="fullscreen-modal">
            <Modal.Body>
              <img src={imageSrc} alt="Image" className="fullscreen-gif" onClick={toggleModal} />
            </Modal.Body>
          </Modal>
        </Container>
        <Container id="benefits" className='home-detail_section' style={{paddingTop: '100px'}}>
        <Row className='d-flex justify-content-center'>
              <Col xs={12} md={8}>
                <p className='mt-3 xlarge-font blue-font text-center mb-0'>Supercharge productivity</p>
                <p className='xlarge-font blue-font text-center'>for a fraction of the effort</p>
                <p className="mt-3 text-center">
                  Teams who find answers quickly have the upper hand. Using Hoppa means you no longer have to open, read, interpret and categorise files manually, enabling you to benefit from exemplar information control in a few clicks. Use our savings calculator to see how much effort and cost our AI could help you avoid.
                </p>
              </Col>
            </Row>
          <div className='padding-medium'>
              <RoiCalculator />
          </div>
        </Container>
        <Container id="about" className='home-detail_section' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '120px', paddingBottom: '8px' }}>
          <MainHeader title='Future-proofing our built world' subtitle="Creating and improving the built world is important and complex work. We're a passionate team of industry experts and technology practitioners, commmited to supporting the builders of today and enabling the builders of tomorrow." />
          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', width: '100%' }}>
            <Stack style={{ maxWidth: '900px', alignItems: 'center', marginTop: '24px' }}>
              <Typography variant='h1' className='blue mt-4' sx={{ fontSize: '2rem', lineHeight: 0.9, marginBottom: '16px' }}>
                Meet the founding team
              </Typography>
            </Stack>
          </div>
          <Stack sx={{ margin: '16px 0' }} direction={{ sm: 'column', md: 'row' }} spacing={{ xs: 1, sm: 2 }}>
            <Card
              style={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '16px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <CardActionArea style={{ padding: '16px', }} onClick={() => { window.open('https://www.linkedin.com/in/sam-rees-9062487a/', '_blank', 'noopener,noreferrer') }}>
                <Stack direction='row'>
                  <Avatar
                    alt='Sam Rees'
                    src={`${process.env.PUBLIC_URL}/Sam_Headshot_Edited - small.jpg`}
                    sx={{ width: 80, height: 80 }}
                  />
                  <Stack style={{ marginLeft: '8px', justifyContent: 'center', minWidth: '250px' }}>
                    <Typography
                      variant="body1"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      Sam Rees
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: '#9ca3af',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      CEO & Co-Founder
                    </Typography>
                  </Stack>
                </Stack>
                <img
                  alt='LinkedIn Logo'
                  src={`${process.env.PUBLIC_URL}/LI-In-Bug.png`}
                  style={{
                    position: 'absolute',
                    right: '12px',
                    bottom: '16px',
                    height: '24px'
                  }} />
              </CardActionArea>
            </Card>
            <Card
              style={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '16px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <CardActionArea style={{ padding: '16px', }} onClick={() => { window.open('https://www.linkedin.com/in/tom-goldsmith', '_blank', 'noopener,noreferrer') }}>
                <Stack direction='row'>
                  <Avatar
                    alt='Tom Goldsmith'
                    src={`${process.env.PUBLIC_URL}/Tom_Headshot_Edited - small.jpg`}
                    sx={{ width: 80, height: 80 }}
                  />
                  <Stack style={{ marginLeft: '8px', justifyContent: 'center', minWidth: '250px' }}>
                    <Typography
                      variant="body1"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      Tom Goldsmith
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: '#9ca3af',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      CTO & Co-Founder
                    </Typography>
                  </Stack>
                </Stack>
                <img
                  alt='LinkedIn Logo'
                  src={`${process.env.PUBLIC_URL}/LI-In-Bug.png`}
                  style={{
                    position: 'absolute',
                    right: '12px',
                    bottom: '16px',
                    height: '24px'
                  }} />
              </CardActionArea>
            </Card>
          </Stack>

          <Container className='mt-5'>
            <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', width: '100%' }}>
              <Stack style={{ maxWidth: '900px', alignItems: 'center' }}>
                <Typography variant='h1' className='blue' sx={{ fontSize: '2rem', lineHeight: 0.9, marginBottom: '16px' }}>
                  Resource Centre
                </Typography>
                <Typography variant='body1' style={{ maxWidth: '700px', marginBottom: '12px' }}>
                  Things that excite, move or inspire us that we'd like to share with you.
                </Typography>
              </Stack>
            </div>
            <Grid
              container
              justifyContent="flex-start" // Align the grid items to the left
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <ArticleCard
                  header="Text to Uniclass Classifier"
                  subHeader="Need to tag your construction schedules, drawings and more to Uniclass? Input a snippet of your data and discover the matching code now for free!"
                  imageURL={`${process.env.PUBLIC_URL}/text-to-uniclass.png`}
                  tag='Free Tool'
                  capability='Data classification'
                  authorName='Hoppa'
                  authorImageURL={`${process.env.PUBLIC_URL}/logo192.png`}
                  publishedDate="Dec 05, '24"
                  linkURL="/text-to-uniclass"

                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ArticleCard
                  header="Four ways to future-proof your built asset data"
                  subHeader="Why productivity gains are the tip of the iceberg when it comes to built asset data strategy, and what new technology could mean for forward-looking asset managers."
                  imageURL={`${process.env.PUBLIC_URL}/building-279769_640.png`}
                  tag='Article'
                  duration='4-5 mins read'
                  authorName='Tom Goldsmith'
                  authorImageURL={`${process.env.PUBLIC_URL}/Tom_Headshot_Edited - small.jpg`}
                  publishedDate="Oct 30, '24"
                  linkURL="https://www.linkedin.com/pulse/four-ways-future-proof-your-built-asset-data-tom-goldsmith-unskf/?trackingId=Gb%2FAl%2F%2BsqQYZ4IfVqrBn6Q%3D%3D"

                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ArticleCard
                  header="Paying Down the Data Debt: Legacy Infrastructure in the Digital Age"
                  subHeader="Why bringing legacy asset information up to modern standards holds the key to a safer, more resilient built world."
                  imageURL="https://substackcdn.com/image/fetch/w_1280,h_853,c_fill,f_webp,q_auto:good,fl_progressive:steep,g_auto/https%3A%2F%2Fsubstack-post-media.s3.amazonaws.com%2Fpublic%2Fimages%2F82352853-5e9b-41bd-80cb-a0d56ccf9f5e_1078x1063.jpeg"
                  tag='Article'
                  duration='5-6 mins read'
                  authorName='Sam Rees'
                  authorImageURL={`${process.env.PUBLIC_URL}/Sam_Headshot_Edited - small.jpg`}
                  publishedDate="Oct 01, '24"
                  linkURL="https://samrees.substack.com/p/solving-the-data-debt-legacy-infrastructure-digital-age-asset-building-facility-management-information-safety-efficiency"

                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ArticleCard
                  header="Is More Technology Really the Answer to Construction's Productivity Problem?"
                  subHeader="An exploration of the disconnect between tech innovation and meaningful transformation in the built environment."
                  imageURL="https://images.unsplash.com/photo-1561679543-696e0cf19f39?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzMDAzMzh8MHwxfHNlYXJjaHwxNzZ8fGFic3RyYWN0JTIwc3RlZWx8ZW58MHx8fHwxNzI2ODMwNTQ0fDA&ixlib=rb-4.0.3&q=80&w=1080"
                  tag='Article'
                  duration='6-8 mins read'
                  authorName='Sam Rees'
                  authorImageURL={`${process.env.PUBLIC_URL}/Sam_Headshot_Edited - small.jpg`}
                  publishedDate="Sep 20, '24"
                  linkURL="https://samrees.substack.com/p/is-more-technology-really-the-answer-to-constructions-productivity-problem-transformation-digital-data-innovation-rethink-contech-proptech"

                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <ArticleCard
                  header="Illustrative Use Case Pack"
                  subHeader="Discover the ways Hoppa supercharges teams day-to-day, and how much your business stands to save."
                  imageURL={`${process.env.PUBLIC_URL}/How_hoppa_can_help_your_organisation.png`}
                  tag='Use Cases'
                  duration='10-15 mins read'
                  authorName='Hoppa'
                  authorImageURL={`${process.env.PUBLIC_URL}/logo192.png`}
                  publishedDate="Sep 05, '24"
                  linkURL={`${process.env.PUBLIC_URL}/How_hoppa_can_help_your_organisation.pdf`}

                />
              </Grid>
            </Grid>
          </Container>
        </Container>
        <div>
          <Container id="contact" className='home-detail_section'>
            <div className='padding-xhuge mb-1' style={{ justifyItems: 'center', paddingTop: '120px' }}>
              <MainHeader title='Got a question?' />
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', alignItems: 'center', maxWidth: '700px' }}>
                <p>To learn about our services or share some feedback you can reach us at <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a>. Or, if you'd like to be first in line for exciting updates from hoppa HQ:</p>
                <div style={{ justifyItems: 'center' }}>
                  <button className='btn btn-primary mb-5' onClick={handleManualSubscribeModalRecall}>
                    Subscribe to our mailing list
                  </button>
                </div>
                <div className='request-demo-card' style={{ maxWidth: '500px', justifyItems: 'center' }}>
                  <p className='large-font black-font'>Arrange a demo</p>
                  <p>Find out more about our cutting-edge AI and discover how Hoppa can help you cut costs, de-risk delivery and eliminate waste. </p>
                  <RequestADemo />
                </div>
              </div>
            </div>
          </Container>
        </div>
      </main>
      <footer>
        <FooterBar />
      </footer>
      <Overlay
        subscribeModalShown={subscribeModalShown}
        content={
          <>
            <h2>Subscribe to our mailing list</h2>
            <p>Receive regular updates from Hoppa HQ.</p>
            <Subscribe />
          </>
        } />
      <Popup
        show={showSubscribeModal}
        onHide={handleCloseSubscribeModal}
        image='labor-7576514_640.png'
        title='Join our mailing list'
        aria-labelledby="mailing-list-modal-title"
        aria-describedby="mailing-list-modal-description"
        content={
          <div >
            <p id="mailing-list-modal-description">Receive regular updates from Hoppa HQ.</p>
            <Subscribe />
          </div>
        }
        showCloseButton={false}
      >
      </Popup>
    </div >
  );
};
export default Home;
