import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

const Login = () => {
    const { loginWithRedirect } = useAuth0();
    const location = useLocation();

    useEffect(() => {
        // Parse query parameters from the URL
        const searchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(searchParams.entries());

        // Redirect to Auth0 login with the query parameters
        loginWithRedirect({
            authorizationParams: params, // You can adjust how params are used or stored
        });
    }, [loginWithRedirect, location.search]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            Redirecting to authentication provider. Please wait...
        </div>
    );
};

export default Login;
