import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderBar from './shared/HeaderBar';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import FooterBar from './shared/FooterBar';

const AccessDeniedPage = () => {
    const navigate = useNavigate(); // Initialize the useNavigate hook
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const goHome = () => {
        navigate('/'); // Navigate to the homepage
    };

    useEffect(() => {
        document.title = "403 Forbidden Error | Hoppa"; // Set the browser tab title here
    }, []); // Empty dependency array ensures this runs only on component mount

    // For conditionally styling components based on window width. 
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <div className='full-width-error-section'>
                <Row className='ms-2 me-0'>
                    <div className='p-2'>
                        <a href="/" className='logo-link'>
                            <h1 className="logo-blue">hoppa</h1>
                        </a>
                    </div>
                </Row>
                <Container className="h-100">
                    <Row className="align-items-center h-100">
                        <Col className='error-page-text '>
                            <h1>Access Denied!</h1>
                            <p>You do not have permission to access this page. If you believe this is an error, please contact support.</p>
                            <button className='btn btn-primary mb-05' onClick={goHome}>
                                Return home
                            </button>
                        </Col>
                        <Col xs={{ order: windowWidth < 991 ? 'first' : 'last' }} s={12} md={6}>
                            <img
                                src={`${process.env.PUBLIC_URL}/403_error.png`} // Update to the new image for 400 error
                                alt="403 Access Denied"
                                className='img-fluid mt-4 mb-4'
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <footer className='m-0'>
                <FooterBar/>
            </footer>
        </div>
    );
};

export default AccessDeniedPage;
