import React, { useState } from "react";
import { Modal, Button, FormControlLabel, Checkbox, Typography, Chip } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useUserManagement } from '../../contexts/UserManagementContexts';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const RerunFilesModal = ({
  open,
  onClose,
  blobs,
  onRerun,
  selectedBlobs,
  setSelectedBlobs,
}) => {
  const { user } = useUserManagement();
  const userId = user.sub;
  
  // Extract URL params directly in the Modal using useParams
  const { sessionId, currentOrg, workspace } = useParams();

  // Handle select all functionality
  const handleSelectAll = () => {
    if (selectedBlobs.length === blobs.length) {
      setSelectedBlobs([]);  // Deselect all if all are selected
    } else {
      setSelectedBlobs(blobs.map((blob) => blob.blob_name));  // Select all
    }
  };

  const handleRerun = async () => {
    try {
      // Log selected blobs to check if they are selected correctly
      //console.log("Selected blobs:", selectedBlobs);

      // Construct the filePaths array using blob_name
      const filePaths = selectedBlobs.map((blobName) => {
        const blob = blobs.find((b) => b.blob_name === blobName);
        return blob ? blob.blob_name : null;  // Use blob_name here
      }).filter(Boolean);  // Filter out any null values

      // Log the final filePaths to verify the structure
      //console.log("File paths:", filePaths);

      const body = {
        filePaths,
        organisation: currentOrg,
        workspace: workspace,
        sessionId: sessionId,
        userId: userId,
      };

      // Construct the API URL (no parameters here)
      const url = `${API_BASE_URL}/api/QueueFiles`;

      // Create the config with the required headers
      const config = {
        headers: {
          'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY, // Add the function key as a header
        }
      };

      // Make the API request
      const response = await axios.post(url, body, config);

      console.log("Response from rerun:", response.data);
      
      // After rerun, you can call the onRerun callback to handle any other logic or UI updates
      onRerun(selectedBlobs);
      onClose();  // Close the modal after rerun
    } catch (error) {
      console.error("Error re-running the files:", error);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          margin: "100px auto",
          width: "600px",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          maxHeight: "80vh", // To make sure it doesn't overflow the viewport
        }}
      >
        <Typography variant="h6">Reprocess Files</Typography>
        <div style={{ overflowY: "auto", flex: 1 }}> {/* Make the content scrollable */}
          {/* Select All Checkbox */}
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedBlobs.length === blobs.length}
                onChange={handleSelectAll}
              />
            }
            label="Select All"
          />
          <div>
            {blobs.map((blob) => (
              <div key={blob.blob_name} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedBlobs.includes(blob.blob_name)}  // Use blob_name here
                      onChange={() =>
                        setSelectedBlobs((prev) =>
                          prev.includes(blob.blob_name)
                            ? prev.filter((name) => name !== blob.blob_name)  // Use blob_name here
                            : [...prev, blob.blob_name]  // Use blob_name here
                        )
                      }
                    />
                  }
                  label={blob.file_name}
                />
                {/* Conditionally display the "failed" run_status in a red pill */}
                {blob.run_status === "failed" && (
                  <Chip
                    label="Failed"
                    color="error"
                    style={{
                      marginLeft: "10px",
                      backgroundColor: "#f44336", // Red background for failed
                      color: "white", // White text for visibility
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
          <Button
            onClick={handleRerun}
            variant="contained"
            color="primary"
            style={{ marginRight: "10px" }}
          >
            Rerun Selected
          </Button>
          <Button
            onClick={onClose}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RerunFilesModal;
