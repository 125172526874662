import React, { useState, useEffect } from "react";
import { Typography, Box, Divider, TextField, Stack, Button } from '@mui/material';
import axios from "axios";
import { useParams } from "react-router-dom";
import { Controlled as ControlledEditor } from 'react-codemirror2';
import { Nav, Tab } from 'react-bootstrap';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/python/python';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSave } from "@fortawesome/free-solid-svg-icons";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SessionSettings = ({ systemPrompts, setSystemPrompts, saveSession, triggerAlert }) => {
    const [selectedTab, setSelectedTab] = useState(1);

    const handleSaveSession = () => {
        saveSession()
        triggerAlert('Save successful', 'success')
    }

    const handleTabSelect = (tab) => {
        setSelectedTab(tab);
    };

    // Update systemPrompts object based on the changed field
    const handleSystemPromptChange = (field, value) => {
        const updatedSystemPrompts = { ...systemPrompts, [field]: value };
        setSystemPrompts(updatedSystemPrompts);
    };

    return (
        <Box sx={{ margin: '1.5rem', flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <div style={{ width: '100%' }}>
                <Box
                    sx={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                        height: '50px'
                    }}
                >
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#5C5DD8', flexGrow: 1 }}>
                        Settings
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{
                        alignItems: "center",
                    }}>
                        <Button variant="text" sx={{ color: '#999999', textTransform: 'none' }} onClick={handleSaveSession}>
                            <FontAwesomeIcon size='sm' icon={faSave} className='me-1' />
                            Save draft
                        </Button>
                    </Stack>
                </Box>
            </div>
            <div style={{ width: '100%' }}>
                <Tab.Container activeKey={selectedTab} onSelect={handleTabSelect}>
                    <Nav className="nav nav-pills">
                        <Nav.Item>
                            <Nav.Link eventKey={1}>Describe</Nav.Link>
                        </Nav.Item>
                        <Divider style={{ margin: '0 2px' }} />
                        <Nav.Item>
                            <Nav.Link eventKey={2}>Classify</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey={3}>Query</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey={4}>Tag</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Divider style={{ border: '1px solid grey', margin: '8px 0' }} />
                    <Tab.Content>
                        <Tab.Pane eventKey={1}>
                        <TextField
                                label="System Prompt"
                                value={systemPrompts?.describe}
                                onChange={(e) => {
                                    handleSystemPromptChange('describe', e.target.value);  // Update 'describe' field
                                }}
                                multiline
                                rows={6}
                                fullWidth
                                variant="outlined"
                                className="mt-2"
                                helperText= {`${systemPrompts?.describe?.length || 0} characters, approx ${Math.round((systemPrompts?.describe?.length || 0)/ 4)} tokens`}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={2}>
                        <TextField
                                label="System Prompt"
                                value={systemPrompts?.classify}
                                onChange={(e) => {
                                    handleSystemPromptChange('classify', e.target.value);  // Update 'describe' field
                                }}
                                multiline
                                rows={6}
                                fullWidth
                                variant="outlined"
                                className="mt-2"
                                helperText= {`${systemPrompts?.classify?.length || 0} characters, approx ${Math.round((systemPrompts?.classify?.length || 0) / 4)} tokens`}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={3}>
                        <TextField
                                label="System Prompt"
                                value={systemPrompts?.query}
                                onChange={(e) => {
                                    handleSystemPromptChange('query', e.target.value);  // Update 'describe' field
                                }}
                                multiline
                                rows={6}
                                fullWidth
                                variant="outlined"
                                className="mt-2"
                                helperText= {`${systemPrompts?.query?.length|| 0} characters, approx ${Math.round((systemPrompts?.query?.length || 0) / 4)} tokens`}
                            />
                        </Tab.Pane>
                        <Tab.Pane eventKey={4}>
                        <TextField
                                label="System Prompt"
                                value={systemPrompts?.tag}
                                onChange={(e) => {
                                    handleSystemPromptChange('tag', e.target.value);  // Update 'describe' field
                                }}
                                multiline
                                rows={6}
                                fullWidth
                                variant="outlined"
                                className="mt-2"
                                helperText= {`${systemPrompts?.tag?.length || 0} characters, approx ${Math.round((systemPrompts?.tag?.length || 0) / 4)} tokens`}
                            />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </Box>
    );
};

export default SessionSettings;
