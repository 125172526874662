import React, { useState, useEffect, useCallback } from "react";
import { Typography, Box, Divider } from '@mui/material';
import DefineWorkspaceStandard from '../DefineWorkspaceStandard'
import { Stack, Chip } from "@mui/material";
import { Form, Nav, Tab, Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import tagSuggestions from '../../../baseStandards/tagSuggestions.json'


const CustomiseSessionEnrichers = ({ sessionStandard, setSessionStandard, saveStandard }) => {
    const [validated, setValidated] = useState(false);
    const [searchTermName, setSearchTermName] = useState('');
    const [searchTermBody, setSearchTermBody] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const [tagInput, setTagInput] = useState('');
    const [availableTags, setAvailableTags] = useState([]);

    useEffect(() => {
        setAvailableTags(tagSuggestions);
    }, []);

    const handleAddSearchTerm = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!searchTermName || !searchTermBody) {
            setValidated(true);
            return;
        }
        setValidated(false);

        const newTerm = {
            searchTerm: `${searchTermName}; ${searchTermBody}`,
            name: searchTermName,
            body: searchTermBody,
            active: true,
        };

        const updatedSearchTerms = editIndex !== null
            ? sessionStandard.enrichers.searchTerms.map((term, idx) => (idx === editIndex ? newTerm : term))
            : [...sessionStandard.enrichers.searchTerms, newTerm];

        setSessionStandard((prevSessionStandard) => {
            const updatedSessionStandard = {
                ...prevSessionStandard,
                enrichers: {
                    ...prevSessionStandard.enrichers,
                    searchTerms: updatedSearchTerms,
                },
            }
            // Call saveStandard with the updated session standard
            saveStandard(updatedSessionStandard);

            // Return the updated state to complete setSessionStandard
            return updatedSessionStandard;
        });

        setSearchTermName('');
        setSearchTermBody('');
        setEditIndex(null);
    };

    const handleRemoveSearchTerm = (index) => {
        const updatedSearchTerms = sessionStandard.enrichers.searchTerms.filter((_, i) => i !== index);

        setSessionStandard((prevSessionStandard) => {
            const updatedSessionStandard = {
                ...prevSessionStandard,
                enrichers: {
                    ...prevSessionStandard.enrichers,
                    searchTerms: updatedSearchTerms,
                },
            }

            // Call saveStandard with the updated session standard
            saveStandard(updatedSessionStandard);

            // Return the updated state to complete setSessionStandard
            return updatedSessionStandard;
        });
    };

    const handleEditSearchTerm = (index) => {
        setEditIndex(index);
        const term = sessionStandard.enrichers.searchTerms[index];
        setSearchTermName(term.name);
        setSearchTermBody(term.body);
    };

    const handleTagSelect = (tag) => {
        const updatedTags = sessionStandard.enrichers.tags.includes(tag)
            ? sessionStandard.enrichers.tags.filter((t) => t !== tag) // Remove tag
            : [...sessionStandard.enrichers.tags, tag]; // Add tag

        setSessionStandard((prevSessionStandard) => {
            const updatedSessionStandard = {
                ...prevSessionStandard,
                enrichers: {
                    ...prevSessionStandard.enrichers,
                    tags: updatedTags,
                },
            }
            // Call saveStandard with the updated session standard
            saveStandard(updatedSessionStandard);

            // Return the updated state to complete setSessionStandard
            return updatedSessionStandard;
        });
    };

    const handleTagInputChange = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
        setTagInput(event.target.value);
    };

    const handleAddTag = (tag) => {
        if (tag.trim() !== '') {
            const updatedTags = [...sessionStandard.enrichers.tags, tag.trim()];

            setSessionStandard((prevSessionStandard) => {
                const updatedSessionStandard = {
                    ...prevSessionStandard,
                    enrichers: {
                        ...prevSessionStandard.enrichers,
                        tags: updatedTags,
                    },
                }
                // Call saveStandard with the updated session standard
                saveStandard(updatedSessionStandard);

                // Return the updated state to complete setSessionStandard
                return updatedSessionStandard;
            });

            setTagInput('');
        }
    };

    const isTagSelected = (tag) => {
        return sessionStandard.enrichers.tags.includes(tag);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleAddTag(tagInput);
        }
    };


    return (
        <>
            {sessionStandard &&
                <Stack direction={{ md: 'column', lg: 'row' }} spacing={2}>
                    <div style={{ flexGrow: 1, }}>
                        <h4>Search terms</h4>
                        <Form noValidate
                            validated={validated}
                            onSubmit={handleAddSearchTerm}

                        >
                            <div className="d-flex align-items-center">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Search term name (e.g. Published Date)"
                                    value={searchTermName}
                                    onChange={(e) => setSearchTermName(e.target.value)}
                                />
                                <Button variant="secondary" type='submit'>
                                    {editIndex !== null ? "Update" : "Add"}
                                </Button>
                            </div>
                            <Form.Control
                                required
                                as="textarea"
                                rows={3}
                                placeholder="Search term body (e.g. 'find the date when the document was last published.')"
                                value={searchTermBody}
                                onChange={(e) => setSearchTermBody(e.target.value)}
                                className='mb-2'
                            />
                            <Form.Control.Feedback type="invalid">
                                Name and body cannot be blank.
                            </Form.Control.Feedback>
                        </Form>
                        {sessionStandard.enrichers.searchTerms.length > 0 && (
                            <Table hover responsive style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    {sessionStandard.enrichers.searchTerms.map((term, index) => (
                                        <tr key={index}>
                                            <td style={{ wordWrap: 'break-word', width: '30%', paddingLeft: "20px" }}>{term.name}</td>
                                            <td style={{ wordWrap: 'break-word', width: '70%' }}>{term.body}</td>
                                            <td className="text-center" style={{ width: '32px' }}>
                                                <FontAwesomeIcon
                                                    icon={faPencilAlt}
                                                    onClick={() => handleEditSearchTerm(index)}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                />
                                            </td>
                                            <td className="text-center" style={{ width: '32px' }}>
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    onClick={() => handleRemoveSearchTerm(index)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: 'red'
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}

                    </div>
                    <Divider style={{ border: '1px solid grey' }} />
                    <div style={{ flexGrow: 1, minWidth: '50%', maxWidth: '50%' }}>
                        <Stack>
                            <h4>Tags</h4>
                            <Form.Group className='mb-3'>
                                <Form.Control
                                    type="text"
                                    placeholder="Type new tag and press enter to create"
                                    value={tagInput}
                                    onChange={handleTagInputChange}
                                    onKeyDown={handleKeyPress}
                                />
                            </Form.Group>
                            <div>
                                <h5>Suggested tags</h5>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {availableTags
                                        .filter(tag => !sessionStandard.enrichers.tags.includes(tag)) // Exclude selected tags
                                        .map((tag) => (
                                            <Chip
                                                key={tag}
                                                label={tag}
                                                className={isTagSelected(tag) ? "tag-selected mb-1 me-1 p-1" : "filledChip mb-1 me-1 p-1"}
                                                onClick={() => handleTagSelect(tag)}
                                            />
                                        ))}
                                </div>
                            </div>
                            <div>
                                <h5>Selected tags</h5>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {sessionStandard.enrichers.tags.map((tag) => (
                                        <Chip
                                            key={tag}
                                            label={tag}
                                            className="tag-selected mb-3 me-1 p-1"
                                            onDelete={() => handleTagSelect(tag)}
                                        />
                                    ))}
                                </div>
                            </div>
                        </Stack>
                    </div>
                </Stack>
            }
        </>
    )

}

export default CustomiseSessionEnrichers