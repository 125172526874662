import Graph from "react-graph-vis";

const response = {
  "description": "The term \"0/4MM COARSE CFA GRITSTONE\" refers to a specific type of coarse gritstone...",
  "pick": {
    "code": "Ma_20_03_15_04",
    "code_description": "Armourstone coarse aggregates",
    "certainty": "medium",
    "explanation": "I classified the document as Armourstone coarse aggregates..."
  },
  "neighbours": [
    { "code": "Ma_20_03_15_04", "title": "Armourstone coarse aggregates", "similarity": 0.82757086 },
    { "code": "Pr_20_93_52_57", "title": "Natural rubble stones", "similarity": 0.8240885 },
    { "code": "Ma_20_03_15_47", "title": "Coarse lightweight aggregates for concrete and mortar", "similarity": 0.82326823 },
    { "code": "Pr_25_93_51_36", "title": "Glass fibre-reinforced concrete (GRC) built-in keystones", "similarity": 0.82246304 },
    { "code": "Pr_20_31_04_04", "title": "Armourstone coarse aggregates", "similarity": 0.82232016 },
    { "code": "Pr_25_93_60_54", "title": "Natural stone cobbles", "similarity": 0.82217216 },
    { "code": "Pr_35_31_06_15", "title": "Granolithic concrete wearing screed mixes", "similarity": 0.8219959 },
    { "code": "Pr_25_93_51_40", "title": "Glass fibre-reinforced concrete (GRC) dressing keystones", "similarity": 0.8218417 },
    { "code": "Ma_40_16_35", "title": "Granulated blast-furnace slag (GBS)", "similarity": 0.8204577 },
    { "code": "Ma_20_03_30_48", "title": "Limestone fines", "similarity": 0.8204098 }
  ]
};

// Helper function to split the code into parts and create nodes for each part
const splitCodeIntoParts = (code) => {
  return code.split('_');
};

const NetworkGraph = () => {
  // Central node for the "Request Node"
  const centralNode = {
    id: 'central',
    label: 'Request Node',
    title: 'Request Node',
    color: '#FF5733', // Central node color (can be customized)
    x: 0, // Position at the center
    y: 0, // Position at the center
  };
  
//   console.log(response)
  
  // Map to keep track of created nodes and avoid duplication
  const nodeMap = {};

  // Function to create or reference an existing node
  const createNode = (id, label) => {
    if (!nodeMap[id]) {
      nodeMap[id] = { id, label, title: label }; // Create a new node if it doesn't exist
      return nodeMap[id]; // Return the existing node if already created
    }
  };

  // Generate the network nodes and edges
  const nodes = [
    centralNode,
    ...response.neighbours.flatMap((neighbor) => {
      // Split the code into parts
      const parts = splitCodeIntoParts(neighbor.code);
      const nodesForThisCode = [];
      let prefix = '';

      // Create or reference a node for each part of the code, maintaining the prefix
      parts.forEach((part, index) => {
        // Build the full prefix at each level
        prefix = index === 0 ? part : `${prefix}_${part}`;
        const node = createNode(prefix, prefix); // Only use the full prefix as the id and label

        // Add the node for the current part if it doesn't exist
        if (node) {
            nodesForThisCode.push(node);
          }
      });

      // Set the color for the leaf node (last part of the code) if it's the picked one
      const leafNode = nodesForThisCode[nodesForThisCode.length - 1];
      leafNode.color = neighbor.code === response.pick.code ? '#1f77b4' : '#7f8c8d';

      // Set the size of the leaf node based on similarity using quadratic scaling
    leafNode.size = Math.max(10, Math.min(30, (Math.pow(neighbor.similarity - 0.8, 3) * 1000000) + 10));

      // Set the title for the leaf node (hover tooltip)
      leafNode.title = `${neighbor.code} : ${neighbor.title} `;

      return nodesForThisCode;
    })
  ];

  console.log(nodes)

  // Generate edges connecting each part of the code (chain)
  const edges = response.neighbours.flatMap((neighbor) => {
    const parts = splitCodeIntoParts(neighbor.code);
    const edgesForThisCode = [];

    for (let i = 0; i < parts.length - 1; i++) {
      const fromNode = parts.slice(0, i + 1).join('_');
      const toNode = parts.slice(0, i + 2).join('_');
      edgesForThisCode.push({
        from: fromNode,
        to: toNode,
        length: 150, // Arbitrary distance to prevent overlap
      });
    }

    // Connect the first part to the central node
    edgesForThisCode.unshift({
      from: 'central',
      to: parts[0],
      length: 150,
    });

    return edgesForThisCode;
  });

  const options = {
    nodes: {
      shape: 'dot',
      size: 15,
    },
    edges: {
      smooth: false,
    },
    physics: {
      enabled: false, // Disable physics for fixed layout
    },
    layout: {
      randomSeed: 2, // Ensures reproducibility
    },
    interaction: {
        zoomView: false,
        dragView: false,
    }
  };

  return (
    <div className="App">
      <h1>Network Visualizer</h1>
      <Graph
        graph={{ nodes, edges }}
        options={options}
        style={{ height: '500px', overflow:'visible' }}
      />
    </div>
  );
};

export default NetworkGraph;