import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate } from "react-router-dom";
import { validateState } from '../scripts/validateState';
import { Box } from '@mui/material';
import { exchangeMicrosoftAuthorizationCode } from '../scripts/exchangeMicrosoftAuthorizationCode';
import { exchangeAutodeskAuthorizationCode } from '../scripts/exchangeAutodeskAuthorizationCode';

const Callback = () => {
    const { action: action, service: service } = useParams();
    const navigate = useNavigate(); // for handling page routing within a function. 

    const redirectGetSharePoint = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code')
        const state = queryParams.get('state')

        let validatedState, organisation, workspace, sessionId, userId;  // Define validatedState outside the try block

        if (state) {
            try {
                validatedState = validateState(state);
            } catch (error) {
                console.error('Invalid or expired state:', error.message);
            }
        }

        if (validatedState) {
            // Extract the container name and session GUID from the validated state
            organisation = validatedState.organisation;
            workspace = validatedState.workspace
            sessionId = validatedState.sessionId;
            userId = validatedState.userId;
        }

        exchangeMicrosoftAuthorizationCode(code, organisation, userId)
        navigate(`/${organisation}/${workspace}/createsession?connector=sharepoint&session=${sessionId}`)

    }

    const redirectGetAutodesk = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code')
        const state = queryParams.get('state')

        // console.log('State is', state)

        let validatedState, organisation, workspace, sessionId, userId;  // Define validatedState outside the try block

        if (state) {
            try {
                validatedState = validateState(state);
            } catch (error) {
                console.error('Invalid or expired state:', error.message);
            }
        }

        console.log('Validated state is', validatedState)

        if (validatedState) {
            // Extract the container name and session GUID from the validated state
            organisation = validatedState.organisation;
            workspace = validatedState.workspace
            sessionId = validatedState.sessionId;
            userId = validatedState.userId;
        }

        exchangeAutodeskAuthorizationCode(code, organisation, userId)
        navigate(`/${organisation}/${workspace}/createsession?connector=autodesk&session=${sessionId}`)


    }

    const redirectLogin = async () => {
        const queryParams = new URLSearchParams(window.location.search);
    }

    useEffect(() => {
        if (action === 'get') {
            if (service === 'sharepoint') {
                redirectGetSharePoint()
            }
            else if (service === 'autodesk') {
                redirectGetAutodesk()
            }
        }
        else if (action === 'login') {
            if (service === 'auth0') {
                redirectLogin()
            }
        }
    }, []);


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', justifyContent: 'center', alignItems: 'center', backgroundColor: 'ghostwhite', position: 'relative' }}>
            <FontAwesomeIcon size='6x' icon={faCircleCheck} color={'green'} className='m-2' />
            <h2>Authentication complete.</h2>
            <p>You are being redirected.</p>
        </Box>
    )
}

export default Callback;