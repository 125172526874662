import React, { useState, useEffect, useCallback } from "react";
import { Grid, Drawer, List, ListItem, ListItemText, ListItemButton, ListItemIcon, IconButton, Typography, Box, Divider, Button, Stack, Chip, Avatar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCopy, faTrashCan, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import FileUpload from "../FileUpload";
import CustomSourceTree from "./CustomSourceTree";
import ImportFromCSV from "./ImportFromCSV";
import CircularProgress from '@mui/material/CircularProgress';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

const ConfigureDataSource = ({ sessionId, allFiles, selectedTab, selectedConnector, handleSetSelectedConnector, handleSetSelectedTab, handleSetSelectedPage, handleUpdateDataSource, handleDeleteDataSource, sourceTreeSharePoint, sourceTreeAutodesk, isLoadingAutodesk, isLoadingSharePoint, handleSetUnsavedChanges, triggerAlert, previewRows, setPreviewRows, rowCount, setRowCount }) => {

    const [fileCount, setFileCount] = useState(0);
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [sourceFiles, setSourceFiles] = useState([]) // Variable to hold source files in state and update the parent component on Save

    const cancelAdd = () => {
        handleSetSelectedTab('Overview')
        handleSetSelectedPage('Overview')
        handleDeleteDataSource('New data source')
        handleSetSelectedConnector(null)
    }

    const saveAdd = () => {
        handleUpdateDataSource(selectedTab, selectedTab, sourceFiles)
        handleSetSelectedTab('Overview')
        handleSetSelectedPage('Overview')
        handleSetSelectedConnector(null)
    }

    const handleSetSourceFiles = (newSourceFiles, source) => {
        handleSetUnsavedChanges(true)

        // Create a new array with the source field added to each file
        const updatedSourceFiles = newSourceFiles.map((file) => ({
            ...file,
            source: source, // Add the source field to each file
        }));

        // Overwrite the sourceFiles state with the updated array
        setSourceFiles(updatedSourceFiles);

        // handleUpdateDataSource(selectedTab, selectedTab, updatedSourceFiles)
    };

    const handleSetFileCount = (newFileCount) => {
        setFileCount(newFileCount)
    }

    const handleSourceRender = () => {
        if (selectedTab === 'File / folder upload') {
            return <FileUpload sessionId={sessionId} handleSetFileCount={handleSetFileCount} allFiles={allFiles} handleSetSourceFiles={handleSetSourceFiles} handleUpdateDataSource={handleUpdateDataSource} triggerAlert={triggerAlert} />
        }
        if (selectedTab === 'Import table from CSV') {
            return <ImportFromCSV sessionId={sessionId} handleSetFileCount={handleSetFileCount} allFiles={allFiles} handleUpdateDataSource={handleUpdateDataSource} triggerAlert={triggerAlert} rowCount={rowCount} setRowCount={setRowCount} previewRows={previewRows} setPreviewRows={setPreviewRows} />
        }
        if (selectedTab === 'Autodesk Construction Cloud') {
            if (isLoadingAutodesk) {
                return (
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        <CircularProgress />
                        <p>Loading data source...</p>
                    </Box>
                )
            }
            else {
                return (
                    <>
                        <div style={{ width: '100%' }}>
                            <Box
                                sx={{
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                    alignItems: 'center',
                                    mb: 2,
                                    height: '50px'
                                }}
                            >
                                {/* Title */}
                                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#5C5DD8', flexGrow: 1 }}>
                                    Configure {selectedTab}
                                </Typography>
                                <Stack direction="row" spacing={1} sx={{
                                    alignItems: "center",
                                }}>
                                </Stack>
                            </Box>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexGrow: 1, marginBottom: '60px' }}>
                            <CustomSourceTree sourceTree={sourceTreeAutodesk} handleSetFileCount={handleSetFileCount} handleSetSourceFiles={handleSetSourceFiles} source='autodesk' />
                        </div>
                    </>
                )

            }
        }
        if (selectedTab === 'Microsoft SharePoint') {
            if (isLoadingSharePoint) {
                return (
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    >
                        <CircularProgress />
                        <p>Loading data source...</p>
                    </Box>
                )
            }
            else {
                return (
                    <>
                        <div style={{ width: '100%' }}>
                            <Box
                                sx={{
                                    justifyContent: 'space-between',
                                    display: 'flex',
                                    alignItems: 'center',
                                    mb: 2,
                                    height: '50px'
                                }}
                            >
                                {/* Title */}
                                <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#5C5DD8', flexGrow: 1 }}>
                                    Configure {selectedTab}
                                </Typography>
                                <Stack direction="row" spacing={1} sx={{
                                    alignItems: "center",
                                }}>
                                </Stack>
                            </Box>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexGrow: 1, marginBottom: '60px' }}>
                            <CustomSourceTree sourceTree={sourceTreeSharePoint} handleSetFileCount={handleSetFileCount} handleSetSourceFiles={handleSetSourceFiles} source='sharepoint' />
                        </div>
                    </>
                )

            }
        }
    }

    return (
        <Box sx={{ margin: '1.5rem', flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative', maxWidth: 'calc(100vw - 240px - 3rem)' }}>
            {handleSourceRender()}
            {/* Bottom action bar */}
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 'calc(-1.5rem)',
                    left: 'calc(-1.5rem)',
                    // right: 'calc(+1.5rem)',
                    height: '64px', // Adjust the height as needed,
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow to the top of the bar
                    padding: '0 1.5rem',
                    boxSizing: 'border-box',
                    width: 'calc(100% + 3rem)'
                }}
            >
                {/* Files Added Message */}
                {/* Files Added Message */}
                <div>
                    <ShoppingBasketIcon style={{ marginRight: '0.5rem' }} />
                    <b>{allFiles.filter(file => file.source !== 'csv').length} file(s) {rowCount > 0 ? `and ${rowCount} row(s)` : ''} in basket</b>
                    {
                        selectedTab === 'File / folder upload' || selectedTab === 'Import table from CSV' ?
                            (
                                <></>
                            )
                            :
                            (<span> | {fileCount} new files selected</span>)
                    }
                </div>


                {
                    selectedTab === 'File / folder upload' || selectedTab === 'Import table from CSV' ?
                        (
                            <></>
                        )
                        :
                        (
                            <Box sx={{ display: 'flex' }}>
                                {/* Cancel Button */}
                                <Button variant="text" sx={{ color: '#999999', textTransform: 'none' }} onClick={() => cancelAdd()}>
                                    <FontAwesomeIcon size='sm' icon={faBan} className='me-1' />
                                    Cancel
                                </Button>

                                {/* Continue Button */}
                                <button className='btn btn-primary mt-2' disabled={fileCount <= 0} onClick={() => saveAdd()}>
                                    Save
                                </button>
                            </Box>
                        )}


            </Box>

        </Box>
    )
}

export default ConfigureDataSource;