import React, { useState, useEffect } from 'react';
import { Link, Grid, Stack, TextField, Box, Button, CircularProgress, Typography, Tooltip, Paper, InputBase, FormControl, FormHelperText, InputLabel, Select, OutlinedInput, Chip, MenuItem, FilledInput, Checkbox, ListItemText, IconButton, Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { marked } from 'marked';
import { ArrowForward, ContentCopy, KeyboardArrowDown, KeyboardArrowUp, KeyboardArrowRight, Security, UnfoldMore, Verified } from '@mui/icons-material';
import FooterBar from './shared/FooterBar';
import NavBar from './shared/NavBar';
import RequestADemo from './subcomponents/RequestADemo';
import Popup from './shared/Popup';
import NetworkGraph from './subcomponents/NetworkGraph';

const API_BASE_URL = process.env.REACT_APP_APIM_BASE_URL;
const CHARACTER_LIMIT = 500

let config = {
    headers: {
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_APIM_KEY, // Add the function key as a header
    },
};

const names = [
    'Ac: Activities',
    'Co: Complexes',
    'EF: Elements / functions',
    'En: Entities',
    'FI: Form of information',
    'Ma: Materials',
    'Pr: Products',
    'PC: Properties and characteristics',
    'PM: Project management',
    'Ro: Roles',
    'RK: Risk',
    'SL: Spaces / locations',
    'Ss: Systems',
    'TE: Tools and equipment',
    'Zz: CAD'
];

const faqs = [
    {
        question: "What is Uniclass?",
        answer: "A comprehensive, standardised classification system used primarily in the construction and built environment sectors."
    },
    {
        question: "How does the tool work?",
        answer: "By using AI NLP techniques to interpret the meaning of your text. Unlike other Uniclass classifiers, we use LLM prompt expansion for higher accuracy on complex or jargon-heavy inputs."
    },
    {
        question: "Who is it for?",
        answer: "The tool is perfect for Planners, Document Controllers, BIM Coordinators & more - anyone needing to organise their data."
    },
    {
        question: "Is this free?",
        answer: "Yes! We're offering this tool free-of-charge so you can experience the benefits of AI-enhanced information management for yourself. "
    },
    {
        question: "What is Hoppa?",
        answer: <>
            Hoppa is the built environment sector's only data refinery for frontline teams. Visit our {' '}
            <a href="https://hoppa.ai" target="_blank" rel="noopener noreferrer">
                homepage
            </a>{' '}
            to find out more.
        </>
    },
    {
        question: "Who maintains Uniclass?",
        answer: "Uniclass is maintained and regularly updated by the National Building Specification (NBS) in the UK to keep it relevant and comprehensive."
    },
]

const TextToUniclass = () => {
    const [text, setText] = useState('');
    const [response, setResponse] = useState({});
    const [isDefault, setIsDefault] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showDescription, setShowDescription] = useState(false); // New state for description animation
    const [tables, setTables] = useState(names)
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [seeMore, setSeeMore] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorTitle, setErrorTitle] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const [showModal, setShowModal] = useState('')

    useEffect(() => {
        document.title = "Text to Uniclass | Hoppa"; // Set the browser tab title here
    }, []); // Empty dependency array ensures this runs only on component mount

    const MenuProps = {
        autoFocus: false,
        PaperProps: {
            sx: {
                marginTop: '8px',
                borderRadius: '16px',
                "& .MuiMenuItem-root.Mui-selected": {
                    backgroundColor: "transparent"
                },
                "& .MuiMenuItem-root:hover": {
                    backgroundColor: "ghostwhite"
                },
            },
        },
        MenuItemProps: {
            sx: {
                padding: '4px 4px',
                paddingRight: '8px',
                '&.Mui-selected': {
                    backgroundColor: 'transparent',  // Remove background on selected items
                    color: '#0051bd', // Change text color for selected items
                },
                '&:hover': {
                    backgroundColor: '#f0f0f0',  // Set hover background
                },
            },
        },
    }

    const handleTableChange = (event) => {
        const {
            target: { value },
        } = event;

        console.log(value)

        if (value[value.length - 1] === "all") {
            setTables(tables.length === names.length ? [] : names);
            return;
        }

        // Use the previous state and compare it to the new value
        setTables((prevTables) => {
            if (typeof value === 'string') {
                return value.split(',');
            }

            return value; // Handle manual selection correctly
        });
    };

    // The classify function
    const classify = async (inputText) => {
        try {
            setIsError(false)
            const query = tables
                .map(table => `subsystem eq '${table.split(': ')[1]}'`)
                .join(' or ');

            const response = await axios.get(
                `${API_BASE_URL}/ClassifyText?text=${inputText}&query=${query}`,
                config
            );

            setResponse(response.data);
        } catch (error) {
            console.error('Error during classification:', error);
            setIsError(true)

            if (error?.response?.data.statusCode === 403) {
                setErrorTitle('Daily Quota Reached')
                setErrorMessage("Come back tomorrow to try again.")
            } else {
                setErrorTitle("An error occurred")
                setErrorMessage("We're experiencing some difficulties, try again later.")
            }
        } finally {
            setIsProcessing(false);
            setShowDescription(true); // Trigger description display
        }
    };

    // Handle submit logic
    const handleSubmit = () => {
        if (text.trim()) {
            setIsProcessing(true);
            setIsDefault(false);
            setShowDescription(false); // Hide description during processing
            classify(text); // Call classify with the input text
        }
    };

    // Handle key press for Enter
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && text.length <= CHARACTER_LIMIT) {
            handleSubmit();
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text); // Copy the text to clipboard
        setTooltipOpen(true); // Show tooltip on successful copy
        setTimeout(() => setTooltipOpen(false), 2000); // Hide tooltip after 2 seconds
    };

    const handleSeeMore = () => {
        if (seeMore) {
            setSeeMore(false)
        } else {
            setSeeMore(true)
        }
    };

    // Handle response description conversion to markdown
    const getMarkdownDescription = (description) => {
        return marked(description); // Convert Markdown to HTML
    };

    return (
        <>
            <NavBar variant='blue'/>
            <Box
                sx={{
                    minHeight: '75vh',
                    backgroundColor: 'ghostwhite',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                {isDefault &&
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            width: '100%',
                            zIndex: 1,
                            justifyContent: 'flex-start',
                            marginTop: { xs: '55px', sm: '90px', md: '100px', lg: '140px' }
                        }}
                    >
                        <Typography sx={{
                            textAlign: 'center',
                            fontSize: '24pt',
                            marginBottom: '8px',
                            opacity: isDefault ? 1 : 0,
                            transition: 'opacity 0.3s ease-in-out',
                            color: '#0051bd'
                        }}>
                            Text to Uniclass
                        </Typography>
                        <Typography sx={{
                            textAlign: 'center',
                            fontSize: '12pt',
                            marginBottom: '150px',
                            opacity: isDefault ? 1 : 0,
                            transition: 'opacity 0.3s ease-in-out',
                            width: {
                                xs: '90%',
                                md: '80%',
                                lg: '60%',
                                xl: '50%'
                            },
                        }}>
                            Need to tag your construction schedules, drawings & more to Uniclass? Input a snippet of your data and discover the matching code now for free!
                        </Typography>


                        <Box sx={{
                            width: {
                                xs: '90%',  // 90% on mobile
                                sm: '80%',  // 80% on small screens
                                md: '70%',  // 70% on medium screens
                                lg: '60%',  // 60% on large screens
                                xl: '50%'   // 50% on extra large screens
                            },
                            // margin: '0 auto',  // Center the Box
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '0px',

                        }}>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'gray',
                                    textAlign: 'center',
                                }}
                            >
                                <Security fontSize='small' sx={{ color: 'green', marginRight: '8px', marginBottom: '1px' }} />
                                Data security comes first – We’ll never sell or share your data. For more details, here’s our{' '}
                                <Link
                                    href="/privacy-policy"
                                    sx={{
                                        color: '#0051bd', // Custom link color
                                        textDecoration: 'none', // No underline
                                        '&:hover': {
                                            textDecoration: 'underline', // Underline on hover
                                        },
                                    }}
                                >
                                    Privacy Policy
                                </Link>
                                .
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'left',
                                    marginTop: '24px',
                                    marginBottom: '18px',
                                    fontSize: '16pt',
                                    color: 'gray'
                                }}
                            >
                                Frequently Asked Questions
                            </Typography>

                            <Grid
                                container
                                justifyContent="flex-start" // Align the grid items to the left
                                // sx={{ backgroundColor: 'gray' }}  // Optional for debugging
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                                {faqs.map((faq, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Box
                                            sx={{
                                                height: '100%',
                                                // backgroundColor: 'white',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                            }}
                                        >
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '12pt', marginBottom: '8px', color: 'gray' }}>
                                                {faq.question}
                                            </Typography>
                                            <Typography variant="body1" sx={{ marginBottom: '8px', color: 'gray' }}>
                                                {faq.answer}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                }

                <Box sx={{
                    marginTop: '100px',
                    position: 'absolute',
                    top: !isDefault ? '0px' : '225px',
                    left: '50%',
                    transform: !isDefault ? 'translate(-50%, 5%)' : 'translate(-50%, -50%)',
                    transition: 'top 0.6s ease, transform 0.6s ease',
                    width: {
                        xs: '90%',
                        md: '80%',
                        lg: '60%',
                        xl: '50%'
                    },
                    zIndex: 10
                }}>
                    <Paper
                        component="form"
                        variant="outlined"
                        sx={{
                            padding: '8px 24px',
                            paddingBottom: '9px',
                            paddingRight: '8px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            borderRadius: '24px',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Custom shadow

                        }}
                    >
                        <FormControl sx={{ flexGrow: 1 }}>
                            <InputBase
                                placeholder="What do you want to classify?"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                onKeyDown={e => handleKeyPress(e)}
                                sx={{
                                    display: 'flex',
                                    flexGrow: 1,
                                    marginRight: '8px',
                                    p: 0,
                                    backgroundColor: 'white',
                                    borderRadius: '16px',
                                    fontSize: { xs: '14pt', md: '16pt' },
                                }}
                            />

                        </FormControl>
                        <FormControl
                            sx={{
                                display: {
                                    xs: 'none',   // Hide for extra-small screens (mobile)
                                    sm: 'block',  // Show for small screens and larger
                                },
                                width: { xs: '150px', md: 180 },
                                '& .MuiFilledInput-root::before': {
                                    borderBottom: '0px !important',
                                },
                                '& .MuiFilledInput-root::after': {
                                    borderBottom: '0px !important',
                                },
                                padding: 0
                            }}>
                            <Select
                                multiple
                                value={tables}
                                onChange={handleTableChange}
                                input={<FilledInput />}
                                IconComponent={UnfoldMore}
                                sx={{
                                    borderRadius: '16px',
                                    // border: '1px solid lightgray',
                                    height: '48px',
                                    backgroundColor: 'white',
                                    width: '190px',
                                    '& .MuiFilledInput-input': {
                                        padding: '8px 8px', // Adjusts the internal padding
                                    },
                                    "&:hover": {
                                        backgroundColor: 'transparent'
                                    },
                                    "&.Mui-focused": {
                                        backgroundColor: 'transparent',
                                        color: '#0051bd'
                                    },
                                    // Change icon color on hover or focus
                                    // Increase specificity for the icon
                                    '& .MuiSelect-icon': {
                                        color: 'gray', // Default icon color
                                    },
                                    // Change icon color when hovering over the Select (targeting the icon specifically within the Select)
                                    '&:hover .MuiSelect-icon': {
                                        color: '#0051bd', // Icon color when Select is hovered
                                    },
                                    // Change icon color when Select is focused
                                    '&.Mui-focused .MuiSelect-icon': {
                                        color: '#0051bd', // Icon color when Select is focused
                                    },
                                }}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: 0.5 }}>
                                        {selected.length > 0 && (
                                            <>
                                                <Chip
                                                    key={selected[0]} // Show the first value
                                                    variant='outlined'
                                                    label={selected[0]}
                                                    sx={{
                                                        display: 'flex',
                                                        maxWidth: selected.length > 1 ? `calc(100% - 40px)` : '100%',
                                                        flexGrow: 1,
                                                        borderColor: '#0051bd',
                                                        color: '#0051bd',
                                                        borderRadius: '8px'
                                                    }}
                                                />
                                                {selected.length > 1 && (
                                                    <Chip
                                                        key="others"
                                                        variant="outlined"
                                                        label={`+${selected.length - 1}`} // Show count of other items
                                                        sx={{
                                                            width: '40px',
                                                            borderColor: '#0051bd',
                                                            color: '#0051bd',
                                                            borderRadius: '8px',
                                                            '.MuiChip-label': {
                                                                p: 0
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                {/* Select All / None Toggle */}
                                <MenuItem
                                    value='all'
                                    sx={{
                                        padding: '4px 4px',
                                        paddingRight: '8px',
                                    }}
                                >
                                    <Checkbox
                                        indeterminate={
                                            tables.length > 0 && tables.length < names.length
                                        }
                                        checked={tables.length === names.length}
                                        sx={{
                                            '&.Mui-checked': {
                                                color: '#0051bd',
                                            },
                                            '&.MuiCheckbox-indeterminate': {
                                                color: '#0051bd',
                                            },
                                        }}
                                    />
                                    <ListItemText
                                        primary="Select All"
                                        primaryTypographyProps={{
                                            fontWeight: 'bold',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden'
                                        }}
                                    />
                                </MenuItem>
                                {names.map((name) => (
                                    <MenuItem
                                        key={name}
                                        value={name}
                                        sx={{
                                            padding: '4px 4px',
                                            paddingRight: '8px',
                                            '&.Mui-selected': {
                                                backgroundColor: 'none'
                                            }
                                        }}
                                    >
                                        <Checkbox checked={tables.includes(name)}
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#0051bd', // Change checkbox color when checked
                                                },
                                            }}
                                        />
                                        <ListItemText primary={name} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <IconButton
                            onClick={handleSubmit}
                            disabled={!text || tables.length === 0 || text.length > CHARACTER_LIMIT} // Disable button if input or dropdown is empty
                            sx={{
                                '&:hover': {
                                    color: '#0051bd', // Change color on hover
                                    backgroundColor: 'transparent'
                                },
                            }}>
                            <ArrowForward size='medium' />
                        </IconButton>

                    </Paper>
                    <Typography sx={{
                        m: 0,
                        p: '4px 24px',
                        color: text.length > CHARACTER_LIMIT ? 'crimson' : 'gray',
                        width: {
                            xs: '90%',
                            md: '80%',
                            lg: '60%',
                            xl: '50%'
                        },
                        justifyContent: 'left'
                    }}
                    >
                        {`${text.length}/${CHARACTER_LIMIT}`}
                    </Typography>
                </Box>
                <Box sx={{
                    width: {
                        xs: '90%',
                        md: '80%',
                        lg: '60%',
                        xl: '50%'
                    },
                    textAlign: 'center',
                    justifyItems: 'center',
                    marginBottom: '32px',
                    position: 'relative'
                }}>
                    {
                        isProcessing && (
                            <div style={{
                                position: 'absolute', // Absolutely position it relative to the parent Box
                                top: '65%',
                                left: '50%',
                                transform: 'translate(-50%, -100%)', // Center horizontally and adjust vertically
                                color: '#0051bd',
                                zIndex: 2, // Ensure it's on top of other content
                                opacity: isProcessing ? 1 : 0,
                                transition: 'opacity 1s ease-in-out',

                            }}>
                                <CircularProgress
                                />

                            </div>

                        )
                    }
                    {!isDefault &&
                        <Box
                            sx={{
                                opacity: showDescription ? 1 : 0,
                                transition: 'opacity 1s ease-in',
                                paddingTop: { xs: '140px', sm: '160px', md: '180px' },
                            }}
                        >
                            <Tooltip
                                open={tooltipOpen}
                                title="Copied to clipboard"
                                arrow
                                placement="top"
                            >
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: 'center',
                                        margin: '16px',
                                        cursor: isError ? undefined : 'pointer',
                                        '&:hover': {
                                            color: isError ? undefined : '#0051bd', // Change color on hover
                                        }
                                    }}
                                    onClick={isError ? undefined : () => handleCopy(response?.pick?.code)}
                                >
                                    {isError
                                        ? errorTitle
                                        : (
                                            <>
                                                {response?.pick?.code}
                                                <ContentCopy sx={{ marginLeft: '8px' }} />
                                            </>
                                        )
                                    }
                                </Typography>
                            </Tooltip>
                            <Typography variant="h6" sx={{ textAlign: 'center', margin: '16px' }}>
                                {isError
                                    ?
                                    errorMessage
                                    : (
                                        response?.pick?.code_description?.length > 0 &&
                                        <>
                                            Category is{' '}
                                            {response?.pick?.code_description}
                                            {' '}
                                            {response?.pick?.certainty === 'high' &&

                                                <Verified color='success' />
                                            }
                                        </>


                                    )
                                }
                            </Typography>
                            <button
                                onClick={handleSeeMore}
                                style={{
                                    marginTop: '0px',
                                    marginBottom: '16px',
                                    padding: '8px 25px',
                                    width: '240px',
                                    backgroundColor: 'transparent',
                                    color: 'gray',
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    fontSize: '16px',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s ease',
                                }}
                            >
                                {seeMore ? 'Hide similar results' : 'View similar results'}
                                {seeMore ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </button>
                            {seeMore &&
                                response.neighbours.map((neighbour, index) => (
                                    neighbour.code !== response.pick.code ?
                                        <p key={index}>{neighbour.code} {neighbour.title}</p> : null
                                ))
                            }
                            <div style={{ textAlign: 'left', marginBottom: '32px' }}>
                                {isError ? (
                                    <></>
                                ) : (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: getMarkdownDescription(response?.description || ''),
                                        }}
                                    />
                                )}
                            </div>
                        </Box>
                    }
                    <Typography variant='h6' sx={{
                        textAlign: 'center',
                        marginTop: '48px',
                        marginBottom: '16px'

                    }}>
                        Need a bigger Hoppa? Get in touch:
                    </Typography>
                    <button className='btn btn-tertiary me-3' onClick={() => setShowModal(true)}>
                        Speak to the team
                    </button>
                    {/* {Object.keys(response).length > 0 &&
                        <NetworkGraph request={text} response={response} />
                    } */}
                </Box>
            </Box>
            <Popup
                show={showModal}
                onHide={() => setShowModal(false)}
                title="Let's chat"
                image='labor-7576514_640.png'
                imageAlt='Get started'
                content={
                    <Box sx={{ display: 'flex', flexGrow: 1 }}>
                        <RequestADemo sx={{ display: 'flex', flexGrow: 1 }} />
                    </Box>

                }
            />
            <footer style={{ marginTop: 0 }}>
                <FooterBar />
            </footer>
        </>
    );
};

export default TextToUniclass;
