import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import Chip from '@mui/material/Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Portal } from '@mui/base/Portal';
import { useUserStandard } from '../../contexts/InitiateContexts';
import tagSuggestions from '../../baseStandards/tagSuggestions.json';

const DefineEnrichers = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [validated, setValidated] = useState(false);

  const [searchTermName, setSearchTermName] = useState('');
  const [searchTermBody, setSearchTermBody] = useState('');
  const [editIndex, setEditIndex] = useState(null);

  const { userStandard, dispatch } = useUserStandard();
  const [enrichers, setEnrichers] = useState({
    tags: [],
    searchTerms: [],
  });

  const container = useRef(null);

  const [tagInput, setTagInput] = useState('');
  const [searchTermInput, setSearchTermInput] = useState('');

  useEffect(() => {
    setAvailableTags(tagSuggestions);
  }, []);

  useEffect(() => {
    sessionStorage.setItem('enrichers', JSON.stringify(enrichers));
    console.log(enrichers);
  }, [enrichers]);

  useEffect(() => {
    dispatch({ type: 'SET_ENRICHERS', payload: enrichers });
  }, [enrichers, dispatch]);

  const handleTagSelect = (tag) => {
    if (enrichers.tags.includes(tag)) {
      handleRemoveTag(tag);
    } else {
      handleAddTag(tag);
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setEnrichers((prevEnrichers) => ({
      ...prevEnrichers,
      tags: prevEnrichers.tags.filter((tag) => tag !== tagToRemove),
    }));
  };

  const handleTagInputChange = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
    setTagInput(event.target.value);
  };

  const handleAddTag = (tag) => {
    if (tag.trim() !== '') {
      setEnrichers((prevEnrichers) => ({
        ...prevEnrichers,
        tags: [...prevEnrichers.tags, tag.trim()],
      }));
      setTagInput('');
    }
  };

  const isTagSelected = (tag) => {
    return enrichers.tags.includes(tag);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddTag(tagInput);
    }
  };

  const handleSearchTermInputChange = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
    setSearchTermInput(event.target.value);
  };

  const handleAddSearchTerm = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!searchTermName || !searchTermBody) {
      setValidated(true);
      return;
    }
    setValidated(false);

    const newTerm = { 
      searchTerm: `${searchTermName}; ${searchTermBody}`, 
      name: searchTermName, 
      body: searchTermBody 
    };

    setEnrichers((prevEnrichers) => ({
      ...prevEnrichers,
      searchTerms: editIndex !== null 
        ? prevEnrichers.searchTerms.map((term, idx) => idx === editIndex ? newTerm : term)
        : [...prevEnrichers.searchTerms, newTerm],
    }));

    setSearchTermName('');
    setSearchTermBody('');
    setEditIndex(null);
  };

  const handleRemoveSearchTerm = (index) => {
    setEnrichers((prevEnrichers) => ({
      ...prevEnrichers,
      searchTerms: prevEnrichers.searchTerms.filter((_, i) => i !== index),
    }));
  };

  const handleEditSearchTerm = (index) => {
    setEditIndex(index);
    const term = enrichers.searchTerms[index];
    setSearchTermName(term.name);
    setSearchTermBody(term.body);
  };

  return (
    <Container className="rounded-box">
      <Row>
        {/* Left Column for Search Terms */}
        <Col md={6}>
          <div className="text-start">
            <Portal container={container.current}>
              <Form noValidate validated={validated} onSubmit={handleAddSearchTerm}>
                <div className="d-flex align-items-center">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Search Term Name (e.g., Published Date)"
                    value={searchTermName}
                    onChange={(e) => setSearchTermName(e.target.value)}
                  />
                  <Button variant="secondary" type="submit">
                    {editIndex !== null ? "Update" : "Add"}
                  </Button>
                </div>
                <Form.Control
                  required
                  as="textarea"
                  rows={3}
                  placeholder="Search Term Body (e.g., 'find the date when the document was last published.')"
                  value={searchTermBody}
                  onChange={(e) => setSearchTermBody(e.target.value)}
                  className='mb-2'
                />
                <Form.Control.Feedback type="invalid">
                  Name and body cannot be blank.
                </Form.Control.Feedback>
              </Form>
            </Portal>

            <div style={{ marginTop: '20px' }}>
              {enrichers.searchTerms.length > 0 && (
                <Table hover responsive>
                  <thead>
                    <tr>
                      <th className="text-center" style={{ width: '5%' }}>Active</th>
                      <th className="text-center" style={{ width: '5%' }}>Edit</th>
                      <th style={{ paddingLeft: "20px", width: '22%' }}>Name</th>
                      <th style={{ wordWrap: 'break-word' }}>Body</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {enrichers.searchTerms.map((term, index) => (
                      <tr key={index}>
                        <td className="text-center">
                          <input type="checkbox" />
                        </td>
                        <td className="text-center">
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            onClick={() => handleEditSearchTerm(index)}
                            style={{ cursor: 'pointer' }}
                          />
                        </td>
                        <td style={{ wordWrap: 'break-word', maxWidth: '150px', paddingLeft: "20px" }}>{term.name}</td>
                        <td style={{ wordWrap: 'break-word', maxWidth: '300px' }}>{term.body}</td>
                        <td className="text-center">
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => handleRemoveSearchTerm(index)}
                            style={{ cursor: 'pointer', color: 'red' }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </div>
          </div>
        </Col>

        {/* Right Column for Tags */}
        <Col md={6}>
          <div className="text-start">
            <Form.Group className='mb-3'>
              <Form.Control
                type="text"
                placeholder="Enter tag"
                value={tagInput}
                onChange={handleTagInputChange}
                onKeyDown={handleKeyPress}
              />
              <Form.Text className="text-muted">Press Enter to add tag</Form.Text>
            </Form.Group>

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {enrichers.tags.map((tag) => (
                <Chip key={tag} label={tag} className="tag-selected mb-3 me-1 p-1" onDelete={() => handleRemoveTag(tag)} />
              ))}
            </div>

            <h5>Suggested tags</h5>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {availableTags.map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  className={isTagSelected(tag) ? "tag-selected mb-1 me-1 p-1" : "filled mb-1 me-1 p-1"}
                  onClick={() => handleTagSelect(tag)}
                />
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DefineEnrichers;
