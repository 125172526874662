import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import RerunFilesModal from "./RerunFilesModal";  // Import the modal

const ResultsStatusBar = ({ 
  blobsWithProcessedStatus, 
  onFilterChange, 
  setBlobsFilteredByProgress, 
  blobsFilteredByProgress 
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBlobs, setSelectedBlobs] = useState([]);
  const [activeFilter, setActiveFilter] = useState("");  // To track the currently active filter

  

  // Categorize blobs by their status
  const categorizedBlobs = {
    Perfect: blobsWithProcessedStatus.filter((blob) => blob.status === "Pass"),
    Good: blobsWithProcessedStatus.filter((blob) => blob.status === "Warning"),
    Warning: blobsWithProcessedStatus.filter((blob) => blob.status === "Error"),
    AwaitingProcessing: blobsWithProcessedStatus.filter((blob) => blob.status === "Unknown"),
  };

  //console.log(categorizedBlobs)

  const fileCount = {
    perfect: categorizedBlobs.Perfect.length,
    good: categorizedBlobs.Good.length,
    warning: categorizedBlobs.Warning.length,
    awaitingProcessing: categorizedBlobs.AwaitingProcessing.length,
    total: blobsWithProcessedStatus.length,
  };

  // Calculate percentage width for each status bar
  const progressValues = {
    perfect: Math.round((fileCount.perfect / fileCount.total) * 100),
    good: Math.round((fileCount.good / fileCount.total) * 100),
    warning: Math.round((fileCount.warning / fileCount.total) * 100),
  };

  // Calculate remaining space for "Awaiting Processing"
  const totalProgressWidth = progressValues.perfect + progressValues.good + progressValues.warning;
  let remaining = 100 - totalProgressWidth; // Remaining space for "Awaiting Processing"
  progressValues.awaitingProcessing = remaining > 0 ? remaining : 0; // "Awaiting Processing" takes up the remaining space

  // Handle clicking on a progress bar
  const handleProgressBarClick = (status) => {
    if (activeFilter === status) {
      setBlobsFilteredByProgress([]);  // Reset to full list (clear the filter)
      setActiveFilter("");  // Reset active filter
    } else {
      setBlobsFilteredByProgress([]);  // Clear any previous filter
      setActiveFilter("");  // Reset active filter to allow proper clearing
      setTimeout(() => {
        const blobs = categorizedBlobs[status];
        setBlobsFilteredByProgress(blobs);  // Apply the new filter
        setActiveFilter(status);  // Mark this status as active
      }, 0);
    }

    // If onFilterChange is provided, pass the filtered blobs to the parent component
    if (onFilterChange) {
      onFilterChange(blobsFilteredByProgress);
    }
  };


  return (
    <div className="container mb-1" style={{justifyContent: 'right', alignItems: 'right', width: '100%' }}>
      {/* Bootstrap Progress Bar */}
      <div className="progress" style={{ height: "40px", marginTop: "12px", marginBottom: "50px" }}>
        {/* Perfect Files */}
        <div
          className="progress-bar bg-success"
          role="progressbar"
          style={{ width: `${progressValues.perfect}%`, cursor: "pointer" }}
          aria-valuenow={progressValues.perfect}
          aria-valuemin="0"
          aria-valuemax="100"
          onClick={() => handleProgressBarClick("Perfect")}
        >
          {fileCount.perfect > 0 && `${fileCount.perfect} Perfect`}
        </div>

        {/* Good Files */}
        <div
          className="progress-bar"
          role="progressbar"
          style={{ width: `${progressValues.good}%`, cursor: "pointer", backgroundColor: "#5ec34a" }}
          aria-valuenow={progressValues.good}
          aria-valuemin="0"
          aria-valuemax="100"
          onClick={() => handleProgressBarClick("Good")}
        >
          {fileCount.good > 0 && `${fileCount.good} Good`}
        </div>

        {/* Warning Files */}
        <div
          className="progress-bar bg-warning"
          role="progressbar"
          style={{ width: `${progressValues.warning}%`, cursor: "pointer" }}
          aria-valuenow={progressValues.warning}
          aria-valuemin="0"
          aria-valuemax="100"
          onClick={() => handleProgressBarClick("Warning")}
        >
          {fileCount.warning > 0 && `${fileCount.warning} Warning`}
        </div>

        {/* Awaiting Processing Files */}
        <div
          className="progress-bar bg-primary"
          role="progressbar"
          style={{ width: `${progressValues.awaitingProcessing}%`, cursor: "pointer" }}
          aria-valuenow={progressValues.awaitingProcessing}
          aria-valuemin="0"
          aria-valuemax="100"
          onClick={() => setModalOpen(true)}
        >
          {fileCount.awaitingProcessing > 0 && `${fileCount.awaitingProcessing} Awaiting Processing`}
        </div>
      </div>

      {/* RerunFilesModal Component */}
      <RerunFilesModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        blobs={categorizedBlobs.AwaitingProcessing}
        selectedBlobs={selectedBlobs}
        setSelectedBlobs={setSelectedBlobs}
      />
    </div>
  );
};

export default ResultsStatusBar;
