import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import HeaderBar from './shared/HeaderBar';
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Typography, } from '@mui/material';
import Overview from './subcomponents/createSession/Overview';
import Refinery from './subcomponents/createSession/Refinery';
import Sidebar from './subcomponents/createSession/Sidebar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AddDataSource from './subcomponents/createSession/AddDataSource';
import ConfigureDataSource from './subcomponents/createSession/ConfigureDataSource';
import connectors from ".././connectorConfig/connectorConfig.json"
import CustomAlert from "./shared/CustomAlert"
import { useUserManagement, useCheckPermissions } from '../contexts/UserManagementContexts';
import SessionSettings from './subcomponents/createSession/SessionSettings';
import { CheckCircleOutline } from '@mui/icons-material';
import { ContentCopy } from '@mui/icons-material';
import { generateSessionId } from '../scripts/generateSessionId';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

let config = {
    headers: {
        'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY,  // Add the function key as a header
        'Content-Type': 'application/json',
    }
}

const CreateSession = () => {
    // User and permission details
    const { user, isLoading, isLoadingOrgs } = useUserManagement();
    const { currentOrg, workspace } = useParams(); // get organisation and workspace from URL
    const queryParams = new URLSearchParams(window.location.search); // get other parameters if present

    // Session details
    const [sessionName, setSessionName] = useState('New session')
    const [sessionId, setSessionId] = useState(queryParams.get('session'));
    const [sessionStatus, setSessionStatus] = useState('Draft');
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const sessionSourcesRef = useRef();
    const [sessionStandard, setSessionStandard] = useState({})

    const [createdAtUnformat, setCreatedAtUnformat] = useState(null);
    const [createdAt, setCreatedAt] = useState(null);
    const [createdBy, setCreatedBy] = useState(null);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('info'); // You can change the default type as needed

    // Local Navigation
    const [selectedTab, setSelectedTab] = useState('Overview');
    const [selectedPage, setSelectedPage] = useState('Overview');
    const [dataSources, setDataSources] = useState([]);
    const [selectedConnector, setSelectedConnector] = useState(null);
    const [showBackdrop, setShowBackdrop] = useState(true)
    const [showCommitModal, setShowCommitModal] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    // Global Navigation
    const navigate = useNavigate();

    // Source data
    const [allFiles, setAllFiles] = useState([])
    const [rowCount, setRowCount] = useState(0)
    const [previewRows, setPreviewRows] = useState([])
    const [isLoadingSession, setIsLoadingSession] = useState(true);
    const [sourceTreeAutodesk, setSourceTreeAutodesk] = useState([])
    const [sourceTreeSharePoint, setSourceTreeSharePoint] = useState([])
    const [isLoadingSharePoint, setIsLoadingSharePoint] = useState(false);
    const [isLoadingAutodesk, setIsLoadingAutodesk] = useState(false);

    // Permission Check
    const checkPermissions = useCheckPermissions();
    const [hasQueuePermission, setHasQueuePermission] = useState(false); // If user is able to submit sessions for analysis

    // Prompt Settings
    const [systemPrompts, setSystemPrompts] = useState({})

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (unsavedChanges) {
                event.preventDefault();
                event.returnValue = ''; // Standard way to show the confirmation dialog
            }
        };

        // Add the event listener when there are unsaved changes
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [unsavedChanges]); // Re-run the effect if `unsavedEdits` changes

    useEffect(() => {

        if (sessionStatus) {

            saveSession()

        }

    }, [allFiles, sessionStatus])

    useEffect(() => {

        const handleLoadSession = async () => {
            const session = await loadSession();
            return session;
        };

        const loadData = async () => {

            let unformattedDate;

            let newDataSources = []; // Array to accumulate new data sources

            if (queryParams.get('session')) {
                try {
                    const session = await handleLoadSession(); // Await the session loading
                    console.log(session);

                    const sessionProperties = session.properties;
                    const sessionFiles = session.files;
                    const sessionSources = session.sources; // Assign session sources to the ref
                    const sessionStandard = session.standard
                    const previewRows = session.rows.data
                    const rowCount = session.rows.count

                    unformattedDate = new Date(sessionProperties.created_at);
                    setSessionName(sessionProperties.session_name);
                    setSessionId(sessionProperties.session_id);
                    setCreatedBy(sessionProperties.created_by);
                    setSessionStatus(sessionProperties.status);

                    setAllFiles(sessionFiles);
                    setSessionStandard(sessionStandard)
                    setPreviewRows(previewRows)
                    setRowCount(rowCount)

                    // Loop through sessionSources and accumulate new data sources
                    sessionSources.forEach(source => {
                        console.log('source is', source);
                        const connector = connectors.find(connector => connector.key === source);
                        console.log('Connector is', connector);
                        if (connector) {
                            newDataSources.push({ name: connector.displayName });
                        }
                    });

                } catch (error) {
                    console.error('Error loading session:', error);
                }
            } else {
                const newSessionId = generateSessionId();
                setSessionId(newSessionId);
                unformattedDate = new Date(); // Get the current date and time
            }

            const formattedDate = unformattedDate.toLocaleString('en-GB', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            });

            // Store the formatted date in state
            setCreatedAt(formattedDate);
            setCreatedBy(user.name)
            setCreatedAtUnformat(unformattedDate); // Assuming you want to keep the raw date too

            // Handle connectors if present
            if (queryParams.get('connector')) {
                const connectorKey = queryParams.get('connector');
                const connector = connectors.find(connector => connector.key === connectorKey);

                if (connector) {

                    // Check if connector is already in dataSources
                    const isConnectorInDataSources = newDataSources.some(
                        (dataSource) => dataSource.name === connector.displayName
                    );

                    if (!isConnectorInDataSources) {
                        // If connector is not already in dataSources, add it to newDataSources
                        newDataSources.push({ name: connector.displayName });
                    }
                    handleSetSelectedTab(connector.displayName);
                    handleSetSelectedPage('ConfigureDataSource');
                }
            }

            console.log('Data sources are', newDataSources)

            // Finally, update the dataSources state with the accumulated sources
            setDataSources(prevDataSources => [...prevDataSources, ...newDataSources]);
            setShowBackdrop(false)
            setIsLoadingSession(false)
        }

        let isAuthorised;

        if (!isLoading && !isLoadingOrgs) {
            isAuthorised = checkPermissions(['create:sessions'], currentOrg)
            console.log('Authorisation check is:', isAuthorised)
            const queuePermission = checkPermissions(['queue:files'], currentOrg)
            setHasQueuePermission(queuePermission)
            if (isAuthorised) {
                loadData(); // Call the function to load data
            }
            else {
                navigate('/access-denied')
            }

        }

    }, [user, isLoading, isLoadingOrgs]);


    useEffect(() => {

        const loadAutodesk = async () => {
            try {
                const response = await axios.post(
                    `${API_BASE_URL}/api/GetAutodeskTree?containerName=${currentOrg}&userId=${user.sub}`,
                    {},
                    config
                );
                return response.data;  // Return the actual data
            } catch (error) {
                console.error('Error building tree:', error);
            }
        };

        // Kick off loading of data sources early so the config pages are ready if the user needs to access them. 
        const fetchAutodeskData = async () => {
            if (
                dataSources.find(dataSource => dataSource.name === 'Autodesk Construction Cloud')
                &&
                user
                &&
                sourceTreeAutodesk.length === 0
            ) {
                console.log('Loading Autodesk')
                setIsLoadingAutodesk(true)
                const tree = await loadAutodesk();  // Await the resolved data
                setSourceTreeAutodesk(tree);  // Set the resolved data in state
                setIsLoadingAutodesk(false)
                console.log('Autodesk results:', tree)
            }
        };

        const loadSharePoint = async () => {
            try {
                const response = await axios.post(
                    `${API_BASE_URL}/api/GetSharePointTree?containerName=${currentOrg}&userId=${user.sub}`,
                    {},
                    config
                );
                return response.data;  // Return the actual data
            } catch (error) {
                console.error('Error building tree:', error);
            }
        };

        // Kick off loading of data sources early so the config pages are ready if the user needs to access them. 
        const fetchSharePointData = async () => {
            if (dataSources.find(dataSource => dataSource.name === 'Microsoft SharePoint')
                &&
                user
                &&
                sourceTreeSharePoint.length === 0
            ) {
                setIsLoadingSharePoint(true)
                const tree = await loadSharePoint();  // Await the resolved data
                setSourceTreeSharePoint(tree);  // Set the resolved data in state
                setIsLoadingSharePoint(false)
                console.log('SharePoint results:', tree)
            }
        };

        let isAuthorised

        if (!isLoading && !isLoadingOrgs && !isLoadingSession) {

            isAuthorised = checkPermissions(['connect:sources'], currentOrg)
            console.log('Authorisation check is:', isAuthorised)
            if (isAuthorised && dataSources) {
                fetchAutodeskData();  // Call the async function
                fetchSharePointData();
            }
        }

    }, [user, isLoading, isLoadingOrgs, isLoadingSession, dataSources])

    const saveStandard = async (updatedStandard = sessionStandard) => {

        const formData = new FormData();
        formData.append('sessionStandard', new Blob([JSON.stringify(updatedStandard)], { type: 'application/json' }));

        try {
            const response = await axios.post(`${API_BASE_URL}/api/UpdateSessionStandard?organisation=${currentOrg}&workspace=${workspace}&sessionId=${sessionId}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY, // Add function key if necessary
                    },
                }
            );

            console.log('Standard saved successfully.')

        } catch (error) {
            console.error('Error saving standard:', error);
            setAlertMessage('Save unsuccessful', 'error')
        }

    }


    const saveSession = async () => {

        if (sessionId && !isLoading && !isLoadingOrgs) {

            try {
                const sessionData = {
                    session_name: sessionName,
                    session_id: sessionId,
                    created_at: createdAtUnformat.toISOString(),
                    created_by: createdBy,
                    file_count: allFiles.filter(file => file.source !== 'csv').length,
                    row_count: rowCount,
                    completed_at: null,
                    status: sessionStatus,
                    warnings: 0,
                };

                if (Object.keys(systemPrompts).length > 0) {
                    sessionData.prompts = systemPrompts
                }

                const response = await axios.post(`${API_BASE_URL}/api/SaveSessionTrigger?organisation=${currentOrg}&workspace=${workspace}`,
                    sessionData,
                    config
                );

                // You can handle the response here if needed
                console.log('Session saved successfully:', response.data);

                setUnsavedChanges(false)

            } catch (error) {
                console.error('Error saving session:', error);
                setAlertMessage('Save unsuccessful', 'error')
            }

        }

    }

    const loadSession = async () => {
        const sessionId = queryParams.get('session')
        try {
            setIsLoadingSession(true)
            const response = await axios.post(`${API_BASE_URL}/api/LoadSessionTrigger?organisation=${currentOrg}&workspace=${workspace}&session_id=${sessionId}`, {}, config);
            return response.data

        } catch (error) {
            console.error("Failed to load session:", error);
        } finally {
            setIsLoadingSession(false)
        }
    }

    const triggerAlert = (newMessage, newType) => {
        setAlertMessage(newMessage);
        setAlertType(newType);
        setShowAlert(true); // This will trigger the alert to show
    };

    const handleSetSessionName = (newSessionName) => {
        setUnsavedChanges(true)
        setSessionName(newSessionName)
    }

    const handleSetSelectedTab = (newTab) => {
        setSelectedTab(newTab)
    }

    const handleSetSelectedPage = (newPage) => {
        setSelectedPage(newPage)
    }

    const handleAddDataSource = (newDataSource) => {
        setDataSources([...dataSources, newDataSource]); // Add the new data source to the existing list
        console.log('Existing', dataSources)
        console.log('New', newDataSource)
    };

    const handleUpdateDataSource = async (currentName, newName, sourceFiles) => {

        const connector = connectors.find(connector => connector.displayName === currentName);

        // Update the dataSources array
        setDataSources((prevDataSources) =>
            prevDataSources.map((dataSource) => {
                // Check if the current entry's name matches currentName
                if (dataSource.name === currentName) {
                    // Return a new object with the updated name
                    return { ...dataSource, name: newName };
                }
                // If not, return the original entry
                return dataSource;
            })
        );

        // Write files to storage
        if (sourceFiles && currentName !== 'New data source' && selectedTab !== 'File / folder upload' && selectedTab !== 'Import table from CSV') {
            const data = {
                files: sourceFiles,  // Add your sourceFiles under the 'files' object
            };

            try {
                triggerAlert('Saving files...', 'info')
                const response = await axios.post(`${API_BASE_URL}/api/IndexFiles?organisation=${currentOrg}&workspace=${workspace}&session_id=${sessionId}&user_id=${user.sub}&source=${connector.key}`,
                    data,
                    config);
                console.log(response.data)

                triggerAlert('Save successful', 'success')

            } catch (error) {
                console.error("Failed to load session:", error);
                triggerAlert('Save failed', 'warning')
            }
        }

        if (connector) {

            const transformedFiles = sourceFiles?.map(file => ({
                name: file.label || file.name,
                source: file.source || connector.key,
                lastModifiedDate: file.attributes?.lastModifiedTime || file.lastModifiedDateTime || file.lastModifiedDate || 'N/A',
                size: file.size || 'N/A'
            }));
    
            // Update the allFiles state
            setAllFiles((prevAllFiles) => {
                console.log('Current name is', currentName)
                // Remove entries where allFiles.source matches currentName
                const filteredFiles = prevAllFiles.filter(file => file.source !== connector?.key);
    
                // Append the new sourceFiles entries
                const updatedAllFiles = [...filteredFiles, ...transformedFiles];
    
                // Log the updated allFiles
                console.log("Updated allFiles:", updatedAllFiles);
    
                return updatedAllFiles;
            });
            setUnsavedChanges(false)

        }
    }

    // Function to delete a data source by name
    const handleDeleteDataSource = (name) => {
        setDataSources((prevDataSources) =>
            prevDataSources.filter((dataSource) => dataSource.name !== name)
        );
    };

    const handleSetSelectedConnector = (name) => {
        setSelectedConnector(name)
    }

    const handleSetUnsavedChanges = () => {
        if (unsavedChanges) {
            setUnsavedChanges(false)
        }
        else {
            setUnsavedChanges(true)
        }
    }

    const handleRunSession = () => {
        console.log('Run Session!')
        setShowCommitModal(true)
    }

    const handleCommitSession = async () => {
        console.log('Commit Session!')
        setShowCommitModal(false)


        try {
            const response = await axios.post(
                `${API_BASE_URL}/api/QueueFiles`,
                {
                    organisation: currentOrg,
                    workspace: workspace,
                    sessionId: sessionId,
                    userId: user.sub,
                    queueCompleted: true
                },
                config
            )

            setShowSuccessModal(true)
            setSessionStatus('Processing')
            setTimeout(() => navigate(`/${currentOrg}/${workspace}/workspace`), 10000)
        }
        catch (error) {
            console.error('Error committing session:', error); // Log error for debugging
            triggerAlert('Submit failed, Please try again.', 'error')
        }
    }

    const handleTabRender = () => {
        if (selectedPage === 'Overview') {
            return <Overview sessionId={sessionId} sessionName={sessionName} sessionStatus={sessionStatus} handleSetSessionName={handleSetSessionName} createdAt={createdAt} createdBy={createdBy} userName={user?.name} saveSession={saveSession} triggerAlert={triggerAlert} allFiles={allFiles} isLoadingSession={isLoadingSession} hasQueuePermission={hasQueuePermission} handleRunSession={handleRunSession} rowCount={rowCount} />
        }
        if (selectedPage === 'Refinery') {
            return <Refinery sessionId={sessionId} sessionName={sessionName} sessionStandard={sessionStandard} setSessionStandard={setSessionStandard} saveStandard={saveStandard} saveSession={saveSession} triggerAlert={triggerAlert} allFiles={allFiles} isLoadingSession={isLoadingSession} hasQueuePermission={hasQueuePermission} handleRunSession={handleRunSession} />
        }
        else if (selectedPage === 'AddDataSource') {
            return <AddDataSource organisation={currentOrg} workspace={workspace} sessionId={sessionId} handleSetSelectedTab={handleSetSelectedTab} selectedConnector={selectedConnector} handleSetSelectedConnector={handleSetSelectedConnector} handleSetSelectedPage={handleSetSelectedPage} handleUpdateDataSource={handleUpdateDataSource} handleDeleteDataSource={handleDeleteDataSource} dataSources={dataSources} saveSession={saveSession} />
        }
        else if (selectedPage === 'ConfigureDataSource') {
            return <ConfigureDataSource allFiles={allFiles} sessionId={sessionId} selectedTab={selectedTab} selectedConnector={selectedConnector} handleSetSelectedConnector={handleSetSelectedConnector} handleSetSelectedPage={handleSetSelectedPage} handleSetSelectedTab={handleSetSelectedTab} handleUpdateDataSource={handleUpdateDataSource} handleDeleteDataSource={handleDeleteDataSource} sourceTreeAutodesk={sourceTreeAutodesk} isLoadingAutodesk={isLoadingAutodesk} sourceTreeSharePoint={sourceTreeSharePoint} isLoadingSharePoint={isLoadingSharePoint} handleSetUnsavedChanges={handleSetUnsavedChanges} triggerAlert={triggerAlert} previewRows={previewRows} setPreviewRows={setPreviewRows} rowCount={rowCount} setRowCount={setRowCount} />
        }
        else if (selectedPage === 'Settings')
            return <SessionSettings systemPrompts={systemPrompts} setSystemPrompts={setSystemPrompts} saveSession={saveSession} triggerAlert={triggerAlert} />
    }

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'ghostwhite', position: 'relative' }}>
                <HeaderBar
                    className="secondary-background"
                    homeLink="/"
                    homeText="hoppa"
                    userInfo={user}
                    switchingAllowed={false}
                    wide
                />
                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                    <Sidebar organisation={currentOrg} workspace={workspace} selectedTab={selectedTab} handleSetSelectedTab={handleSetSelectedTab} handleSetSelectedPage={handleSetSelectedPage} handleSetSelectedConnector={handleSetSelectedConnector} dataSources={dataSources} handleAddDataSource={handleAddDataSource} handleDeleteDataSource={handleDeleteDataSource} />
                    {handleTabRender()}
                </Box>
            </Box>
            <CustomAlert message={alertMessage} type={alertType} showAlert={showAlert} setShowAlert={setShowAlert} />
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    display: 'flex',
                    flexDirection: 'column', // Align items vertically
                    justifyContent: 'center', // Center vertically
                    alignItems: 'center', // Center horizontally
                }}
                open={isLoading || isLoadingOrgs || showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
                open={showCommitModal}
                onClose={() => setShowCommitModal(false)}
                onSubmit={() => handleCommitSession()}
            >
                <DialogTitle>Run session</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button autoFocus className='btn btn-secondary' onClick={() => setShowCommitModal(false)}>
                        Go back
                    </button>
                    <button className='btn btn-primary' onClick={() => handleCommitSession()}>
                        Run
                    </button>
                </DialogActions>
            </Dialog>
            <Dialog open={showSuccessModal}>
                <Box display="flex" flexDirection="column" alignItems="center" margin='1rem' width='300px'>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width={70}
                        height={70}
                        borderRadius="50%"
                        bgcolor="green"
                        mb={2}
                    >
                        <CheckCircleOutline fontSize="large" style={{ color: 'white' }} />
                    </Box>

                    <Typography align="center" variant="body1" paragraph>
                        Files added to queue.
                        You will be redirected shortly.
                    </Typography>

                    <button
                        className='btn btn-secondary'
                        onClick={() => navigate(`/${currentOrg}/${workspace}/workspace`)}
                    >
                        Return to workspace
                    </button>
                </Box>
            </Dialog>
        </div >
    );
};

export default CreateSession;