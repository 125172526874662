import React, { useState, useMemo } from "react";
import { Card, CardContent, Typography, Box, Chip, CardMedia, Stack, Avatar, CardActionArea } from '@mui/material';
import { AccessTime, Assistant } from "@mui/icons-material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'


const ArticleCard = ({
    header,
    subHeader,
    imageURL,
    tag,
    duration,
    authorName,
    authorImageURL,
    publishedDate,
    linkURL,
    capability
}) => {

    return (
        <Card
            style={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '16px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            }}
        >
            <CardActionArea style={{ padding: '16px', }} onClick={() => {window.open(linkURL, '_blank', 'noopener,noreferrer')}}>
                {/* Image/Category Section */}
                <div style={{ position: 'relative' }}>
                    <img
                        src={imageURL}
                        style={{
                            width: '100%',           // Ensures the image fills the width of the container
                            height: '200px',         // Fixes the height (adjust as needed)
                            borderRadius: '8px',     // Rounds the corners
                            objectFit: 'cover',      // Maintains aspect ratio and crops if needed
                        }}
                    >
                    </img>
                    <Chip
                        label={tag}
                        sx={{
                            position: 'absolute',
                            bottom: '12px',
                            left: '8px',
                            backgroundColor: 'ghostWhite',

                        }}
                    />
                </div>

                {/* Main Content */}
                <CardContent style={{ padding: 0}}>
                    <div style={{height: '170px'}}>
                        <div
                        style={{
                            color: '#9ca3af',
                            margin: '8px 0px',
                        }}
                    >
                        {duration && 
                        <>
                        <AccessTime style={{ height: '20px', marginRight: '4px' }} />
                        <Typography
                            variant="caption"
                        >
                            {duration}
                        </Typography>
                        </>
                    }
                    {capability &&
                    <>
                    <Assistant style={{ height: '20px', marginRight: '4px' }} />
                    <Typography
                        variant="caption"
                    >
                        {capability}
                    </Typography>
                    </>
                    }
                    </div>
                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: 'bold',
                            marginBottom: '8px',
                            lineHeight: 1.3,
                            color: '#1a1a1a',
                            display: '-webkit-box',    // Enables multi-line ellipsis
                            overflow: 'hidden',        // Hides overflowing content
                            textOverflow: 'ellipsis',  // Adds ellipsis for truncated text
                            WebkitLineClamp: 2,        // Limits the text to 2 lines
                            WebkitBoxOrient: 'vertical', // Sets the box orientation to vertical
                            height: `calc(1.3em * 2)`,   // Ensures height is exactly 2 lines
                        }}
                    >
                        {header}
                    </Typography>
                    <Typography
                        variant="body2"
                        style={{
                            color: '#6b7280',
                            marginBottom: '12px',
                            display: '-webkit-box',    // Enables multi-line ellipsis
                            overflow: 'hidden',        // Hides overflowing content
                            textOverflow: 'ellipsis',  // Adds ellipsis for truncated text
                            WebkitLineClamp: 3,        // Limits the text to 2 lines
                            WebkitBoxOrient: 'vertical' // Sets the box orientation to vertical
                        }}
                    >
                        {subHeader}
                    </Typography>
                    </div>
                    {/* Metadata */}
                    <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar alt={authorName} src={authorImageURL} />
                            <Stack style={{ marginLeft: '8px' }}>
                                <Typography
                                    variant="caption"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                   {authorName}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    style={{
                                        color: '#9ca3af',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    {publishedDate}
                                </Typography>
                            </Stack>

                        </Box>
                        <div className='case-study-card-button'>
                            <div className='circle'>
                                <div className='circle-icon' >
                                    <FontAwesomeIcon size='md' icon={faArrowRight} />
                                </div>
                                <div className='circle-icon'>
                                    <FontAwesomeIcon size='md' icon={faArrowRight} />
                                </div>
                            </div>
                        </div>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default ArticleCard;