import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Button, Form, OverlayTrigger, Tooltip, Badge, Modal, Row, Col } from 'react-bootstrap';
import { Box } from '@mui/material';
import axios from 'axios';
import Toast from 'react-bootstrap/Toast';
import HeaderBar from './shared/HeaderBar';
import BackButton from './shared/BackButton';
import DefineStandard from './subcomponents/DefineStandard';
import DefineWorkspaceStandard from './subcomponents/DefineWorkspaceStandard';
import CustomiseWorkspaceStandard from './subcomponents/CustomiseWorkspaceStandard';
import { useWorkspaceStandard } from '../contexts/InitiateContexts';
import { useSession } from '../contexts/SessionContexts';
import '../App.scss';
import HorizontalLinearStepper from './shared/LinearStepper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';

import {
  useNavigate,
  useParams,
} from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const InitiateWorkspace = () => {
  const { user } = useAuth0();
  const { sessionId } = useParams();
  const { currentOrg, workspace } = useParams();
  const navigate = useNavigate();
  
  const { workspaceStandard } = useWorkspaceStandard();
  const { setSession } = useSession();
  const [currentStep, setCurrentStep] = useState(0);
  const [validated, setValidated] = useState(false);
  const [firstTimeConfig, setFirstTimeConfig] = useState(true);
  const [existingTemplate, setExistingTemplate] = useState(null);
  const [newTemplate, setNewTemplate] = useState(null);
  const [clearStandard, setClearStandard] = useState(false);
  const [showOverwriteConfirmation, setShowOverwriteConfirmation] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showStandardHelp, setShowStandardHelp] = useState(false);
  const [showCustomiseHelp, setShowCustomiseHelp] = useState(false);
  const [allCodePartsHaveValues, setAllCodePartsHaveValues] = useState(false)
  const [hasCodeParts, setHasCodeParts] = useState(false)
  const [uploadFromExcel, setUploadFromExcel] = useState(false)
  const cancelTokenSource = useRef(null); // To handle cancel requests
  const [fileOperationInProgress, setFileOperationInProgress] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  // New states
  const [blueprints, setBlueprints] = useState([]);
  const [isLoadingBlueprints, setIsLoadingBlueprints] = useState(false);
  const [blueprintLoadError, setBlueprintLoadError] = useState("")
  const [selectionMade, setSelectionMade] = useState(false);

  const [continueEnabled, setContinueEnabled] = useState(false);

  useEffect(() => {
    console.log("ALL CODE PARTS HAVE VALS", allCodePartsHaveValues)
    console.log("HAS CODE PARTS",hasCodeParts)
}
)

  const steps = ['Choose a standard', 'Customise to suit'];

  const config = {
    headers: { 'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY }
  };

  // Get blueprints from blob
  useEffect(() => {
    const fetchBlueprints = async () => {
      setIsLoadingBlueprints(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/api/GetBlueprintsTrigger`, config);
        setBlueprints(response.data);
      } catch (err) {
        console.error("Error fetching blueprints:", err);
        setBlueprintLoadError(err);
      } finally {
        setIsLoadingBlueprints(false);
      }
    };
    fetchBlueprints();
  }, []);

  console.log(blueprints)

  const isStepOptional = (step) => {
    return step === 1 || step === 2; // Step 2 and 3 are optional. 
  };

  // Google Analytics tracking
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const toggleStandardHelp = () => setShowStandardHelp(!showStandardHelp);
  const toggleCustomiseHelp = () => setShowCustomiseHelp(!showCustomiseHelp);

  const handleStepChange = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const stepIndex = currentStep + 1;

    // Trigger form validation
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    // Handle first-time config or ask for confirmation
    if (firstTimeConfig) {
      setExistingTemplate(newTemplate); // Set existing template from selected blueprint
      setCurrentStep(currentStep + 1);
    } else if (existingTemplate === newTemplate) {
      setCurrentStep(currentStep + 1);
    } else {
      setShowOverwriteConfirmation(true); // Confirm if overwriting existing template
    }
  };

  const overwriteAllCodeParts = () => {
    setExistingTemplate(newTemplate);
    setClearStandard(true);
    setShowOverwriteConfirmation(false);
    setCurrentStep(currentStep + 1);
  };

  const handleSetTemplate = (template) => {
    setNewTemplate(template);
  };

  const handleSetFirstTimeConfig = () => {
    setFirstTimeConfig(false);
  };

  const handleSetClearStandard = (boolean) => {
    setClearStandard(boolean);
  };

  const handleSetToastMessage = (message) => {
    setToastMessage(message);
    setShowToast(true);
  };

  console.log("workspace standard", workspaceStandard)

  const handleSessionCommit = async (event) => {

    setShowOverlay(true)

    const workspaceStandardJSON = JSON.stringify(workspaceStandard);
    const workspaceStandardJSONforBlob = new Blob([workspaceStandardJSON], { type: 'application/json' });

    const formData = new FormData();
    formData.append('file', workspaceStandardJSONforBlob, 'workspace_standard.json');
    formData.append('organisation', currentOrg);
    formData.append('workspace', workspace);

    cancelTokenSource.current = axios.CancelToken.source();

    try {
      setFileOperationInProgress(true); // Set the operation in progress flag

      // Perform the POST request to upload the file
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/CompleteWorkspaceConfigTrigger`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY, // Add function key if necessary
          },
          cancelToken: cancelTokenSource.current.token,
        }
      );

      // Handle success response
      console.log('File uploaded successfully:', response.data);
      setToastMessage('Workspace standard saved successfully!'); // Show a success message
      setShowToast(true); // Trigger toast notification
    } catch (error) {
      console.error('Error uploading file:', error);
      setToastMessage('Error uploading workspace standard.');
      setShowToast(true); // Show error message
    } finally {
      setFileOperationInProgress(false); // Reset file operation progress
      setShowOverlay(false); // Hide overlay or loading indicator
    }
    navigate(`/${currentOrg}/${workspace}/workspace`);
  };

  const renderModalContent = (
    <>
      {showStandardHelp && (
        <Modal.Body>
          <b>Why is this step important?</b>
          <br /><br />
          Information management in the built environment involves organising, storing, retrieving, and utilising data related to construction projects. It enhances collaboration and decision-making throughout the lifecycle of built assets.
          <br /><br />
          <b>Where to start?</b>
          <br /><br />
          Many organisations expect files in a format complying with specific standards like ISO 19650. If you need help building your standard, feel free to reach out at <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a>.
        </Modal.Body>
      )}
      {showCustomiseHelp && (
        <Modal.Body>
          <b>Why is this step important?</b>
          <br /><br />
          Customising your standard ensures your files are organised in a way that suits your project. Define classifiers, set reference codes, and add descriptions to guide how files are classified.
          <br /><br />
          <b>Got a request or need help?</b>
          <br /><br />
          Reach out to <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a> if you need help.
        </Modal.Body>
      )}
    </>
  );

  return (
    <div>
      <HeaderBar homeText="hoppa" userInfo={user} />
      <div className="full-width-white-bottom-section">
      <Container>
                <Row  className="mt-2">
                    <Col>
                        <BackButton destinationText="workspaces" destination="/welcome" warning={true} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span style={{ fontSize:"1.1rem"}}>
                            <Badge bg="info" className="mt-1 mb-4">{workspace}</Badge>
                        </span>
                    </Col>
                </Row>
                {/*<HorizontalLinearStepper steps={steps} isStepOptional={isStepOptional} activeStep={currentStep} />*/}
                </Container>
                </div>
      <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide className="position-fixed top-0 start-50 translate-middle-x">
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      <div className="full-width-lt-blue-section">
        <Form noValidate validated={validated} onSubmit={(event) => handleStepChange(event)}>
          
          <Container className="pt-3 mb-5">
            <div className={currentStep === 0 ? '' : 'hidden'}>
              {/*<Container className="blue-font background-fade-purple-sunburst mt-3 mb-3 pt-3 pb-3 position-relative">
                <h2>Define a classification standard</h2>
                <Badge pill bg="primary" className="position-absolute top-0 end-0 mt-1" style={{ cursor: 'pointer' }}>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-info">Click for help 💡</Tooltip>}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} className="text-white" onClick={toggleStandardHelp} />
                  </OverlayTrigger>
                </Badge>
              </Container>*/}
              <DefineWorkspaceStandard 
                blueprints={blueprints}
                handleSetTemplate={handleSetTemplate}
                setHasCodeParts={setHasCodeParts}
                setUploadFromExcel={setUploadFromExcel}
                setContinueEnabled={setContinueEnabled}
                 />
            </div>
            <div className={currentStep === 1 ? '' : 'hidden'}>
              {/*<Container className="blue-font background-fade-purple-sunburst mt-3 mb-3 pt-3 pb-3 position-relative">
                <h2>Customise the standard to suit your needs</h2>
                <Badge pill bg="primary" className="position-absolute top-0 end-0 mt-1" style={{ cursor: 'pointer' }}>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-info">Click for help 💡</Tooltip>}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} className="text-white" onClick={toggleCustomiseHelp} />
                  </OverlayTrigger>
                </Badge>
              </Container>*/}
              <CustomiseWorkspaceStandard
                blueprints = {blueprints}
                workspaceStandard={workspaceStandard}
                template={existingTemplate}
                firstTimeConfig={firstTimeConfig}
                handleSetFirstTimeConfig={handleSetFirstTimeConfig}
                clearStandard={clearStandard}
                handleSetClearStandard={handleSetClearStandard}
                step={currentStep}
                setHasCodeParts={setHasCodeParts}
                hasCodeParts={hasCodeParts}
                setAllCodePartsHaveValues={setAllCodePartsHaveValues}
                handleSetToastMessage={handleSetToastMessage}
              />
            </div>
          </Container>
          <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '64px', // Adjust the height as needed
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow to the top of the bar
                    padding: '0 1.5rem'
                }}
            >
                {/* Cancel Button */}
                {currentStep > 0 && (
                  <Button
                      variant="text"
                      sx={{ color: '#999999', textTransform: 'none' }}
                      onClick={() => setCurrentStep(currentStep - 1)}
                  >
                      Back
                  </Button>
              )}
                {/* Continue Button */}
                <button 
                  className='btn btn-primary mt-2' 
                  onClick={currentStep === steps.length - 1 ? handleSessionCommit : undefined}
                  disabled={currentStep === 0 ? !continueEnabled : !(allCodePartsHaveValues && hasCodeParts)}
                  >
                {currentStep === steps.length - 1 ? 'Finish' : 'Continue'}
                </button>
            </Box>
        </Form>
        
      </div>
      <Modal show={showOverwriteConfirmation} onHide={() => setShowOverwriteConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to overwrite all code parts?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowOverwriteConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={overwriteAllCodeParts}>
            Overwrite All
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showStandardHelp || showCustomiseHelp} onHide={toggleStandardHelp || toggleCustomiseHelp} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Help</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderModalContent}</Modal.Body>
      </Modal>
    </div>
  );
};

export default InitiateWorkspace;
